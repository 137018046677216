<template>
  <component :is="svg"></component>
</template>

<script>
export default {
  name: 'ImgSvg',
  props: {
    src: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      svg: '',
    }
  },
  created() {
    this.svg = require(`~/assets/images/${this.src}?inline`)
  },
}
</script>
