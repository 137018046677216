<template>
  <ul
    class="u-menu-footer justify-content-between"
    :data-component="componentName"
  >
    <!-- TODO: Vyměnit `:key` -->
    <li v-for="(item, index) in menu" :key="index" class="u-menu-footer__item">
      <a class="u-menu-footer__link" :href="item.href" target="_blank">{{
        item.title
      }}</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'FooterMenu',
  props: {
    menuData: { type: Array, required: true },
  },
  data() {
    return {
      componentName: this.$options.name,
      menu: this.menuData,
    }
  },
}
</script>

<style lang="scss">
.u-menu-footer {
  padding-top: map-get($spacers, 4);
  padding-bottom: map-get($spacers, 3);
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  border-top: solid $gray-100 3px;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: map-get($spacers, 5);
    padding-bottom: map-get($spacers, 4);
  }

  &__item {
    @include media-breakpoint-down(sm) {
      &:not(:last-child) {
        margin-bottom: $spacer;
      }
    }
  }

  &__link {
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}
</style>
