<template>
  <div>
    <span
      class="modal-header__close"
      :data-component="componentName"
      @click="$bvModal.hide(modalId)"
    >
      <span v-responsive.sm.xs="">
        <img-svg width="30" height="30" src="modal-close.svg"></img-svg>
      </span>
      <span v-responsive.md.lg.xl="">
        <img-svg width="50" height="50" src="modal-close--md.svg"></img-svg>
      </span>
    </span>
  </div>
</template>

<script>
import ImgSvg from './common/imgSvg'

export default {
  name: 'ModalHeader',
  components: {
    ImgSvg,
  },
  props: {
    modalId: { type: String, required: true },
  },
  data() {
    return {
      componentName: this.$options.name,
    }
  },
}
</script>
