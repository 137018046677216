<template>
  <ul class="app-menu">
    <li v-for="item in menuItems" :key="item.sysPageKey" class="app-menu__item">
      <nuxt-link
        :to="$sysPage(item.sysPageKey)"
        :class="[
          'app-menu__item-link',
          $sysPage(item.sysPageKey) === $route.path
            ? 'app-menu__item-link--active'
            : null,
        ]"
      >
        <img-svg
          width="30"
          height="30"
          :src="`app-nav-${item.sysPageKey}.svg`"
        ></img-svg>
        <span class="app-menu__item-link-title">{{ item.title }}</span>
      </nuxt-link>
    </li>
    <li class="app-menu__item">
      <a
        v-b-toggle.collapse-nav
        href="#"
        class="app-menu__item-link"
        @click.prevent="$toggleBodyModalClass"
      >
        <img-svg width="30" height="30" :src="`app-nav-menu.svg`"></img-svg>
        <span class="app-menu__item-link-title">Menu</span>
      </a>
    </li>
  </ul>
</template>

<script>
import ImgSvg from '~/components/common/imgSvg'
import { getUser } from '~/config/common'

export default {
  name: 'AppMenu',
  components: {
    ImgSvg,
  },
  data() {
    return {
      user: null,
    }
  },
  computed: {
    menuItems() {
      const subscriptionBasedItem =
        this.user && this.user.subscribtionType === 'PREPAID'
          ? [
              {
                title: 'Dobíjení',
                sysPageKey: 'recharge',
              },
              {
                title: 'Odměny',
                sysPageKey: 'rewards',
              },
            ]
          : [
              {
                title: 'Vyúčtování',
                sysPageKey: 'postpaidPayment',
              },
            ]

      return [
        {
          title: 'Přehled',
          sysPageKey: 'overview',
        },
        ...subscriptionBasedItem,
        {
          title: 'Balíčky',
          sysPageKey: 'packages',
        },
      ]
    },
  },
  mounted() {
    this.user = getUser()
  },
}
</script>

<style lang="scss">
.body--is-app {
  padding-bottom: 3.125rem;
}

.app-menu {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 3.125rem;
  padding: 0.625rem;
  margin: 0;
  background-color: $gray-900;

  &__item {
    display: block;
  }

  &__item-link {
    display: block;
    text-align: center;
    color: $white;

    &:hover {
      color: $white;
    }

    &--active {
      color: $secondary;

      &:hover {
        color: $secondary;
      }
    }
  }

  &__item-link-title {
    display: block;
    font-size: 0.5625rem;
  }
}
</style>
