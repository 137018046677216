<template>
  <div>
    <ul
      v-if="usersList && usersList.length"
      class="editUser__list"
      :data-name="$options.name"
    >
      <li
        v-for="user in usersList"
        :key="user.nickname"
        class="editUser__listItem"
      >
        <div class="editUser__name">
          {{ user.nickname }}
        </div>
        <a class="editUser__icon" @click="removeUserAlias(user.nickname)">
          <svg
            class="bi bi-trash-fill editUser__icon--red"
            width="1.5em"
            height="1.2em"
            viewBox="0 0 16 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"
            />
          </svg>
        </a>
      </li>
    </ul>
    <p v-if="newUserAdded" class="editUser__added-msg">Úspéšně přidáno!</p>

    <a
      v-if="!isCreatingNewUser"
      class="editUser__listItem editUser__listItem--new mt-2"
      @click="isCreatingNewUser = !isCreatingNewUser"
    >
      <div class="editUser__addNew">Přidat účet</div>
      <div class="editUser__icon--blue">
        <svg
          class="bi bi-plus"
          width="1.5em"
          height="1.5em"
          viewBox="0 0 16 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"
          />
          <path
            fill-rule="evenodd"
            d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"
          />
        </svg>
      </div>
    </a>
    <CreateUserForm
      v-else
      :users-list="usersList"
      @userAddedToList="userAddedToListHandler"
      @collapseCreateUserForm="isCreatingNewUser = false"
    ></CreateUserForm>
  </div>
</template>

<script>
import CreateUserForm from './CreateUserForm'
import {
  getSavedUsersList,
  updateSavedUsersList,
  getUser,
} from '~/config/common'

export default {
  name: 'EditUsers',
  components: {
    CreateUserForm,
  },
  data() {
    return {
      isCreatingNewUser: false,
      newUserAdded: false,
      usersList: [],
      currentUser: null,
      initialLoad: true,
    }
  },

  // TODO: if pass the test, remove this event driven piece of shit
  // watch: {
  //   usersList(newValue, oldValue) {
  //     this.newUserAdded = false
  //     const lsUpdated = updateSavedUsersList(
  //       newValue,
  //       this.currentUser.accountId
  //     )

  //     // using >= operator because of JS Arrays/Object nature
  //     console.log('INIT', this.initialLoad)

  //     if (
  //       lsUpdated &&
  //       newValue.length >= oldValue.length &&
  //       !this.initialLoad
  //     ) {
  //       this.newUserAdded = true
  //     }
  //   },
  // },
  mounted() {
    const currentUser = getUser()
    this.currentUser = currentUser
    const usersList = getSavedUsersList(currentUser.accountId) ?? []
    this.usersList = usersList
    this.$nextTick(() => {
      this.initialLoad = false
    })
  },
  methods: {
    userAddedToListHandler(data) {
      this.usersList.push(data)
      this.$emit('userListChange', this.usersList)
      this.isCreatingNewUser = false
      updateSavedUsersList(this.usersList, this.currentUser.accountId)

      this.newUserAdded = true
    },
    removeUserAlias(nickname) {
      this.usersList = this.usersList.filter(
        (item) => item.nickname !== nickname
      )

      updateSavedUsersList(this.usersList, this.currentUser.accountId)
      this.$emit('userListChange', this.usersList)
      this.newUserAdded = false
    },
  },
}
</script>
<style lang="scss">
.editUser {
  &__list {
    padding: 0;
    margin-top: 16px;
    list-style: none;
  }

  &__listItem {
    position: relative;
    display: flex;
    padding: 7px 7px;
    font-weight: $font-weight-bolder;

    &:hover {
      color: $red;
    }

    &--new:hover {
      text-decoration: none;
      cursor: pointer;

      > div::after,
      div::before {
        display: none;
      }
    }
  }

  &__name {
    flex: 1 0 auto;
    cursor: pointer;
  }

  &__addNew {
    flex: 1 0 auto;
    color: $blue;

    &:hover {
      text-decoration: none;
    }
  }

  &__icon {
    flex: 0 0 auto;
    border-radius: 50%;
    cursor: pointer;

    &--red {
      &:hover {
        color: $red;
      }
    }

    &--blue {
      color: $blue;

      &:hover {
        color: $blue;
      }
    }
  }

  &____addNewItem {
    font-weight: $font-weight-bolder;
    color: $blue;
  }

  &__added-msg {
    padding-left: 7px;
    margin-top: -1.5rem;
    font-size: 0.75rem;
    color: $green;
  }
}
</style>
