export const parseJwt = function (token) {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    console.log(e)
    return null
  }
}

export default {
  methods: {
    parseJwt,
  },
}
