<template>
  <b-modal v-model="biometricsModalIsVisible" hide-footer hide-header>
    <div class="modalHeader">
      <span class="modal-header__close" @click="dismiss">
        <img-svg height="30" src="modal-close--md.svg" width="30"></img-svg>
      </span>
    </div>
    <h3 class="text-center mb-5">
      Chcete aktivovat<br />přihlašování pomocí biometrie?
    </h3>
    <b-button href="/osobni-udaje#biometrie" block class="mb-4">
      Přejít na aktivaci
    </b-button>
    <b-button variant="primary" block class="mb-4" @click="dismiss">
      Ne, děkuji
    </b-button>
    <p>
      Aktivaci či deaktivaci můžete provést kdykoliv v sekci
      <a href="/osobni-udaje#biometrie">Osobní - biometrie</a>
    </p>
  </b-modal>
</template>

<script>
import {
  getBiometricModalDismissed,
  setBiometricModalDismissed,
} from '@/config/common'
import imgSvg from '@/components/common/imgSvg'

export default {
  name: 'PwdlessLoginInfoModal',
  components: {
    imgSvg,
  },
  data() {
    return {
      biometricsModalIsVisible: false,
    }
  },
  mounted() {
    this.biometricsModalIsVisible =
      this.$uvpaaAvailable && !getBiometricModalDismissed()
    setBiometricModalDismissed()
  },
  methods: {
    dismiss() {
      setBiometricModalDismissed()
      this.biometricsModalIsVisible = false
    },
  },
}
</script>
