import { DateTime } from 'luxon'

const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () =>
      resolve({
        name: file.name,
        data: reader.result,
      })
    reader.onerror = (error) => reject(error)
  })

const fileListToBase64 = async (fileList) => {
  return await Promise.all(
    fileList.map(async (file) => await fileToBase64(file))
  )
}

const phonePrefixed420 = (phoneNumber) => {
  return (
    '420' +
    phoneNumber
      .replace(/\s/g, '')
      .split('')
      .reverse()
      .join('')
      .substr(0, 9)
      .split('')
      .reverse()
      .join('')
  )
}

export default {
  methods: {
    /**
     * Parses JS Object to JSON string
     * according to options to conform API schema
     *
     * @param {object} dataAsObject - data to be parsed to JSON
     * @param {object} options - additional options
     *
     * @return {string} - JSON string
     */
    async getJsonData(dataAsObject, options) {
      const objectCopy = JSON.parse(JSON.stringify(dataAsObject))

      // parse files to base64 {filesToBase64: [keys_of_file_options_list]}
      if (Object.prototype.hasOwnProperty.call(options, 'filesToBase64')) {
        for await (const item of options.filesToBase64) {
          // item is the key of dataAsObject files item
          if (Array.isArray(objectCopy[item])) {
            objectCopy[item] = await fileListToBase64(dataAsObject[item])
          }
        }
      }

      // parses phone numbers to begin with 420 for BE purposes
      if (Object.prototype.hasOwnProperty.call(options, 'phonePrefixed420')) {
        for (const item of options.phonePrefixed420) {
          if (objectCopy[item]) {
            if (Array.isArray(objectCopy[item])) {
              objectCopy[item] = objectCopy[item].map((item) =>
                phonePrefixed420(item)
              )
            } else {
              objectCopy[item] = phonePrefixed420(objectCopy[item])
            }
          }
        }
      }

      // parses "street n/m" to separate street, n, m fields
      if (
        Object.prototype.hasOwnProperty.call(options, 'addressToStreetCoCp')
      ) {
        for (const item of options.addressToStreetCoCp) {
          if (objectCopy[item].street && objectCopy[item].street.length > 0) {
            // eslint-disable-next-line prefer-regex-literals
            const cpCoRegExp = new RegExp('\\s[\\d]+(/.*)?', 'g')

            // get cp/co part of the address from the street param
            const cpCo = objectCopy[item].street.match(cpCoRegExp)
            if (cpCo) {
              const cpCoLastMatch = cpCo.pop()
              const coCpAsArray = cpCoLastMatch
                .split('/')
                .map((item) => item.trim())

              objectCopy[item] = {
                ...objectCopy[item],
                street: objectCopy[item].street
                  .slice(
                    0,
                    objectCopy[item].street.length - cpCoLastMatch.length
                  )
                  .trim(),
                cp: coCpAsArray[0],
                co: coCpAsArray[1] || '',
              }
            }
          }
        }
      }

      // parses "name surname" to separate name, surname fields
      if (Object.prototype.hasOwnProperty.call(options, 'nameSurname')) {
        for (const item of options.nameSurname) {
          const nameSplit = objectCopy[item].split(' ')
          const surname = nameSplit.pop()

          objectCopy[item] = nameSplit.join(' ')
          objectCopy.surname = surname
        }
      }

      if (Object.prototype.hasOwnProperty.call(options, 'date')) {
        for (const item of options.date) {
          objectCopy[item] = DateTime.fromISO(objectCopy[item]).toFormat(
            'yyyy-LL-dd'
          )
        }
      }

      return JSON.stringify(objectCopy)
    },
  },
}
