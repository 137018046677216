<template>
  <div class="btn btn-block text-muted font-weight-light" v-html="text"></div>
</template>

<script>
export default {
  name: 'SubmitLoader',
  props: {
    text: {
      type: String,
      default: 'Načítám&hellip;',
    },
  },
}
</script>
