import Vue from 'vue'
import axios from 'axios'
import Cookies from 'js-cookie'
import { uuid } from 'vue-uuid'
import getJsonData from './../mixins/getJsonData'

const cryptr = require('simple-encryptor')('hb@x]2zg7Bb+Kk}H')

const savedUserListStorageKey = '_ulSession'
const permanentLogedUserStorageKey = 'netMysys_L'

// system pages links map configuration as key:value pairs
// for using keys in templates and code
export const sysPagesMap = {
  dataAboutYou: '/data-o-vas/',
  directDebit: '/nastaveni-inkasa/',
  firstLogin: '/jsem-zde-poprve/',
  forgottenPassword: '/zapomenute-heslo/',
  // rewardsWheel: '/kolo-odmen/',
  login: '/',
  logout: '/odhlaseni/',
  numberTransmission: '/prenos-cisla/',
  overview: '/prehled/',
  packages: '/balicky/',
  packagesActivate: '/balicky-aktivace/',
  payInvoice: '/uhradit-fakturu/',
  personalData: '/osobni-udaje/',
  postpaidPayment: '/tarif-platba/',
  rating: '/hodnoceni/',
  recharge: '/dobiti/',
  reports: '/vypisy/',
  rewards: '/odmeny/',
  service404: '/service-404/',
  services: '/sluzby/',
  confirmEmail: '/overeni-emailu/',
  eSim: '/esim/',
  eSimTransfer: '/eSimTransfer/',
  eSimMigration: '/eSimMigration/',
  personalDataComplaint: '/osobni-udaje-reklamace/',
}

// list of sysPageMap keys for paths, that do not require authentication
export const nonAuthPathKeys = [
  'login',
  'firstLogin',
  'forgottenPassword',
  'service404',
]

export const pages = [
  {
    component: 'pages_components/overview.vue',
    path: sysPagesMap.overview,
    props: {
      title: 'Přehled',
      type: sysPagesMap.overview,
    },
  },
  {
    component: 'pages_components/packages.vue',
    path: sysPagesMap.packages,
    props: {
      title: 'Balíčky',
      type: sysPagesMap.packages,
    },
  },
  {
    component: 'pages_components/personalDataComplaint.vue',
    path: sysPagesMap.personalDataComplaint,
    props: {
      title: 'Formulář k uplatnění individuálních práv',
      type: sysPagesMap.packages,
    },
  },
  {
    component: 'pages_components/reports.vue',
    path: sysPagesMap.reports,
    props: {
      title: 'Výpisy',
      type: sysPagesMap.reports,
    },
  },
  {
    component: 'pages_components/login.vue',
    path: sysPagesMap.login,
    props: {
      title: 'Přihlášení',
      pwaTitle: 'Váš mobilní operátor',
      pwaClass: 'app-text-no-transform',
      footerStyle: 'light',
    },
  },
  {
    component: 'pages_components/logout.vue',
    path: sysPagesMap.logout,
    props: {
      title: 'Odhlášení',
      footerStyle: 'light',
    },
  },
  {
    component: 'pages_components/firstLogin.vue',
    path: sysPagesMap.firstLogin,
    props: {
      title: 'Jsem zde poprvé',
      hasBackArrowOnApp: true,
      footerStyle: 'light',
    },
  },
  {
    component: 'pages_components/forgottenPassword.vue',
    path: sysPagesMap.forgottenPassword,
    props: {
      title: 'Obnova zapomenutého hesla',
      hasBackArrowOnApp: true,
      footerStyle: 'light',
    },
  },
  {
    component: 'pages_components/forgottenPassword.vue',
    path: `${sysPagesMap.forgottenPassword}:phone/:ticketKey`,
    props: {
      title: 'Obnova zapomenutého hesla',
      hasBackArrowOnApp: true,
      footerStyle: 'light',
    },
  },
  {
    component: 'pages_components/rewards.vue',
    path: sysPagesMap.rewards,
    props: {
      title: 'Odměny',
      type: sysPagesMap.rewards,
    },
  },
  {
    component: 'pages_components/recharge.vue',
    path: sysPagesMap.recharge,
    props: {
      title: 'Dobití',
    },
  },
  {
    component: 'pages_components/postpaidPayment.vue',
    path: sysPagesMap.postpaidPayment,
    props: {
      title: 'Vyúčtování k zaplacení',
    },
  },
  {
    component: 'pages_components/directDebit.vue',
    path: sysPagesMap.directDebit,
    props: {
      title: 'Nastavení inkasní platby',
    },
  },
  {
    component: 'pages_components/packagesActivate.vue',
    path: sysPagesMap.packagesActivate,
    props: {
      title: 'Aktivované balíčky',
      hasBackArrowOnApp: true,
    },
  },
  {
    component: 'pages_components/services.vue',
    path: sysPagesMap.services,
    props: {
      title: 'Služby',
    },
  },
  {
    component: 'pages_components/confirmEmail.vue',
    path: sysPagesMap.confirmEmail,
    props: {
      title: 'Ověření e-mailu',
      footerStyle: 'light',
    },
  },
  {
    component: 'pages_components/numberTransmission.vue',
    path: sysPagesMap.numberTransmission,
    props: {
      title: 'Přenos čísla',
    },
  },
  {
    component: 'pages_components/rating.vue',
    path: sysPagesMap.rating,
    props: {
      title: 'Hodnocení',
      type: sysPagesMap.rating,
    },
  },
  // {
  //   component: 'pages_components/rewardsWheel.vue',
  //   path: sysPagesMap.rewardsWheel,
  //   props: {
  //     title: 'Kolo odměn',
  //     type: sysPagesMap.rewardsWheel,
  //   },
  // },
  {
    component: 'pages_components/personalData.vue',
    path: sysPagesMap.personalData,
    props: {
      title: 'Osobní údaje',
    },
  },
  {
    component: 'pages_components/dataAboutYou.vue',
    path: sysPagesMap.dataAboutYou,
    props: {
      title: 'Údaje o vás',
    },
  },
  {
    component: 'pages_components/payInvoice.vue',
    path: sysPagesMap.payInvoice,
    props: {
      title: 'Postpaid overview',
    },
  },
  {
    component: 'pages_components/eSim.vue',
    path: sysPagesMap.eSim,
    props: {
      title: 'E-SIM',
    },
  },
  {
    component: 'pages_components/eSimTransfer.vue',
    path: sysPagesMap.eSimTransfer,
    props: {
      title: 'E-SIM',
    },
  },
  {
    component: 'pages_components/eSimMigration.vue',
    path: sysPagesMap.eSimMigration,
    props: {
      title: 'E-SIM',
    },
  },
  {
    component: 'pages_components/404.vue',
    path: sysPagesMap.service404,
    props: {
      title: 'Stránka nenalezena',
    },
  },
]

export const documentTypeMap = {
  INVOICE: 'Faktura',
  INVOICE_PARTLY_PAID: 'Faktura částečně zaplacená',
  INVOICE_DETAIL: 'Vyúčtování',
  CREDIT_NOTE: 'Dobropis',
  PREPAYMENT: 'Záloha',
}

export const servicesMap = {
  MMS: 'MMS',
  PREMIUMSMS: 'Premium SMS',
  AUDIOTEX: 'Audiotex',
  ROAMING: 'Volání v zahraničí',
  INTERNATIONALCALL: 'Volání do zahraničí',
  MISSEDCALLSALERT: 'Zmeškané hovory',
  COMMUNICATIONPROFILE: 'Info o ceně služby',
  ADULTCONTENTFILTER: 'Dětský zámek',
  PORTEDNUMBERCALLSNOTIFICATION: 'Hláška o volání do jiné sítě',
  WELCOMEROAMINGSMS: 'Info o ceně služeb v roamingu',
}

export const getAuthToken = () => {
  return localStorage.getItem('sessionToken')
    ? cryptr.decrypt(localStorage.getItem('sessionToken'))
    : false
}

export const setAuthToken = (token) => {
  return localStorage.setItem('sessionToken', cryptr.encrypt(token))
}

export const clearAuthToken = () => {
  return localStorage.removeItem('sessionToken')
}

export const getUser = () => {
  return localStorage.getItem('userSession')
    ? JSON.parse(cryptr.decrypt(localStorage.getItem('userSession')))
    : false
}

export const setUser = (user) => {
  return localStorage.setItem(
    'userSession',
    cryptr.encrypt(JSON.stringify(user))
  )
}

export const clearUser = () => {
  return localStorage.removeItem('userSession')
}

export const getSessionContext = () => {
  return localStorage.getItem('sessionContext')
    ? JSON.parse(cryptr.decrypt(localStorage.getItem('sessionContext')))
    : false
}

export const getTotalItemsPrice = (items) => {
  return items.reduce((acc, curr) => +acc + +curr.price, 0)
}

export const setPurchaseItems = (items) => {
  localStorage.setItem('purchaseItems', JSON.stringify(items))
}

export const getPurchaseItems = () => {
  return localStorage.getItem('purchaseItems')
    ? JSON.parse(localStorage.getItem('purchaseItems'))
    : []
}

export const pushItemToLS = (name, itemId, itemPrice, options = {}) => {
  const newItem = {
    name,
    id: itemId,
    price: itemPrice,
    quanity: 1,
    affiliation: 'SAZKAmobil',
    discount: undefined,
    currency: 'CZK',
    coupon: undefined,
    brand: 'SAZKAmobil',
    category: 'credit',
    recurrence: options?.recurrence,
    recharge_period: options?.recharge_period,
    charge_floor: options?.charge_floor,
  }

  const newItems = [newItem]
  setPurchaseItems(newItems)
}

export const setSelectedPhoneNumber = (number) =>
  localStorage.setItem('selectPhone', number)

export const getSelectedPhoneNumber = () => {
  return localStorage.getItem('selectPhone')
}

export const setConfirmPhoneNumber = (number) =>
  localStorage.setItem('confirmPhone', number)

export const getConfirmPhoneNumber = () => {
  return localStorage.getItem('confirmPhone')
}

export const wipeAllPurchaseInfo = () => {
  localStorage.removeItem('confirmPhone')
  localStorage.removeItem('selectPhone')
  localStorage.removeItem('purchaseItems')
}

export const pushPurchaseInfo = (callback) => {
  const items = getPurchaseItems()
  if (!items || items.length === 0) {
    return
  }
  callback()
  wipeAllPurchaseInfo()
}

export const setSessionContext = (contextData) => {
  return localStorage.setItem(
    'sessionContext',
    cryptr.encrypt(JSON.stringify(contextData))
  )
}

export const clearSessionContext = () => {
  return localStorage.removeItem('sessionContext')
}

export const getSavedUsersList = (currentUserId) => {
  if (!currentUserId) {
    return []
  }

  const queryKey = savedUserListStorageKey + currentUserId.toString()

  const data = localStorage.getItem(queryKey)
    ? JSON.parse(cryptr.decrypt(localStorage.getItem(queryKey)))
    : []

  return data
}

export const updateSavedUsersList = (userData, currentUserId) => {
  const queryKey = savedUserListStorageKey + currentUserId.toString()
  try {
    localStorage.setItem(queryKey, cryptr.encrypt(JSON.stringify(userData)))
    return true
  } catch {
    console.error('Error saving user data')
    return false
  }
}

export const setPermanentLoggedUser = (userData) => {
  localStorage.setItem(
    permanentLogedUserStorageKey,
    cryptr.encrypt(JSON.stringify(userData))
  )
}

export const getPermanentLoggedUser = () => {
  return localStorage.getItem(permanentLogedUserStorageKey)
    ? JSON.parse(
        cryptr.decrypt(localStorage.getItem(permanentLogedUserStorageKey))
      )
    : false
}

export const clearPermanentLoggedUser = () => {
  return localStorage.removeItem(permanentLogedUserStorageKey)
}

export const savePaymentMethod = (/* number, */ method) => {
  const storageKey = '_smpmtds'
  // payment methods are saved in string for a single number as a "number:methodKey" pairs separated by ";"
  // let savedMethodsByNumber = (localStorage.getItem(storageKey) || '').split(';');
  //
  // const hasSavedMethod = savedMethodsByNumber.find((numberMethod) => numberMethod.indexOf(number) === 0);
  // if (!hasSavedMethod) {
  //   savedMethodsByNumber.push(`${number}:${method}`);
  // } else {
  //   savedMethodsByNumber = savedMethodsByNumber.map((numberMethod) => {
  //     return numberMethod.indexOf(number) === 0 ? `${number}:${method}` : numberMethod;
  //   });
  // }
  //
  // return localStorage.setItem(storageKey, savedMethodsByNumber.join(';'));

  return localStorage.setItem(storageKey, method)
}

export const getSavedPaymentMethod = () => {
  return localStorage.getItem('_smpmtds')
}

export const getPaswordlessCredentialId = () => {
  return window.localStorage.getItem('pwlcid')
}

export const setPaswordlessCredentialId = (pwlcid) => {
  return window.localStorage.setItem('pwlcid', pwlcid)
}

export const removePaswordlessCredentialId = () => {
  return window.localStorage.removeItem('pwlcid')
}

export const getBiometricModalDismissed = () => {
  return window.localStorage.getItem('bmDis')
}

export const setBiometricModalDismissed = () => {
  return window.localStorage.setItem('bmDis', '1')
}

export const formatPhoneNumberToId = (number) => {
  return number.replace(/ +/g, '').substring(1)
}

export const loginCall = async (vueInstance, username, password) => {
  const JSONdata = await getJsonData.methods.getJsonData(
    {
      username,
      password,
    },
    {
      phonePrefixed420: ['username'],
    }
  )

  const options = {
    data: JSONdata,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'fe-version': vueInstance.$config.version ?? 'dev',
    },
  }

  const loginUrl =
    vueInstance.$webOrApp && vueInstance.$webOrApp === 'app'
      ? '/wsc/login/login/mobile'
      : '/wsc/login/login'
  return await vueInstance.$apiCall(loginUrl, options)
}

export const addIncentiveForRating = async (vueInstance) => {
  const options = {
    method: 'get',
    sendAuth: true,
  }

  return await vueInstance.$apiCall(
    '/wsc/shared/addIncentiveForRating',
    options
  )
}

export const automaticTopupIntervalMap = {
  MONTH_DAY1: '1. den v měsíci',
  MONTH_DAY5: '5. den v měsíci',
  MONTH_DAY10: '10. den v měsíci',
  MONTH_DAY15: '15. den v měsíci',
  MONTH_DAY20: '20. den v měsíci',
  MONTH_DAY25: '25. den v měsíci',
  MONTH_DAY0: 'Poslední den v měsíci',
}

export const rqStatusEnum = {
  SUCCESS: 'success',
  ERROR: 'error',
  PROCESSING: 'processing',
  INVALID: 'invalid',
  DEFAULT: 'default',
}

export const getStrapiData = async () => {
  const { strapiDataQuery } = await import('./queries')
  const { print } = await import('graphql')

  const query = print(strapiDataQuery)
  try {
    // eslint-disable-next-line no-console
    const strapiResponse = await axios(
      `${process.env.NUXT_ENV_STRAPI_URL}/graphql`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        data: {
          query,
        },
      }
    )

    const strapiData = strapiResponse?.data?.data

    // filtering data by workflowStep
    strapiData.rewardsWheelActivities =
      strapiData.rewardsWheelActivities?.data &&
      strapiData.rewardsWheelActivities.data
        ?.map((d) => ({ id: d.id, ...d.attributes }))
        .filter(
          (item) =>
            item &&
            item.entityStatus &&
            item.entityStatus.workflowStep &&
            item.entityStatus.workflowStep === 'publish'
        )

    strapiData.rewardsWheelChallanges =
      strapiData.rewardsWheelChallanges?.data &&
      strapiData.rewardsWheelChallanges?.data
        ?.map((d) => ({ id: d.id, ...d.attributes }))
        .filter(
          (item) =>
            item &&
            item.entityStatus &&
            item.entityStatus.workflowStep &&
            item.entityStatus.workflowStep === 'publish'
        )

    strapiData.phones =
      strapiData.phones &&
      strapiData.phones?.data
        ?.map((d) => ({ id: d.id, ...d.attributes }))
        .filter(
          (item) =>
            item &&
            item.entityStatus &&
            item.entityStatus.workflowStep &&
            item.entityStatus.workflowStep === 'publish'
        )

    return strapiData
  } catch (e) {
    console.log('Strapi data fetch error', e)
  }

  return {}
}

export const callAppRating = () => {
  try {
    window.location.href = 'sazkamobil://hodnoceni'
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }
}

// check iOS - iPhone, iPad, ...
export const isIOS = () => {
  return /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
}

export const trackingPush = (data) => {
  // TODO ADD TEALIUM IMPLEMENTATION HERE
}
/**
 *  consent levels and their cookie category (marketing, statistics, functional - always granted) equivalent
 *  ad_storage: marketing,
 *  analytics_storage: statistics,
 *  functionality_storage: functional,
 *  personalization_storage: marketing',
 *  security_storage: functional',
 */
export const gtmCookieConsentChangeCallback = (
  statisticsGranted,
  marketingGranted,
  thirdpartyGranted,
  lista
) => {
  const user = getUser()

  const consentGranted = []
  const consentRevoked = []

  statisticsGranted && consentGranted.push('analytics')
  marketingGranted && consentGranted.push('marketing')
  thirdpartyGranted && consentGranted.push('thirdParty')
  !statisticsGranted && consentRevoked.push('analytics')
  !marketingGranted && consentRevoked.push('marketing')
  !thirdpartyGranted && consentRevoked.push('thirdParty')

  if (consentGranted.length) {
    const dataForLayer = {
      event: 'action.grantConsent.cmp',
      action: {
        grantConsent: {
          cmp: {
            purposeCodes: consentGranted.join(','),
            deviceId: getCookieId(),
            userId: user?.accountId || undefined,
          },
        },
      },
    }
    if (lista?.$route?.query?.sourceDialog === 'lista') {
      dataForLayer.action.grantConsent.cmp.location = 'mergeUserAccount'
    }
    trackingPush(dataForLayer)
  }

  if (consentRevoked.length) {
    const dataForLayer = {
      event: 'action.revokeConsent.cmp',
      action: {
        revokeConsent: {
          cmp: {
            purposeCodes: consentRevoked.join(','),
            deviceId: getCookieId(),
            userId: user?.accountId || undefined,
          },
        },
      },
    }
    if (lista?.$route?.query?.sourceDialog === 'lista') {
      dataForLayer.action.grantConsent.cmp.location = 'mergeUserAccount'
    }
    trackingPush(dataForLayer)
  }
}

export const setConsentCookies = function (value, validityInDays) {
  if (
    Object.prototype.hasOwnProperty.call(value, 'marketing') &&
    Object.prototype.hasOwnProperty.call(value, 'statistic') &&
    Object.prototype.hasOwnProperty.call(value, 'thirdParty')
  ) {
    return Cookies.set('cookiesaccepted', JSON.stringify(value), {
      expires: validityInDays,
      domain: location.host.split('.').splice(1).join('.'),
    })
  }
  return undefined
}

export const getConsentCookies = function () {
  const consentCookiesValue = Cookies.get('cookiesaccepted')
  try {
    return consentCookiesValue && JSON.parse(consentCookiesValue)
  } catch (e) {
    Cookies.set('cookiesaccepted', '', {
      expires: -1,
      domain: location.host.split('.').splice(1).join('.'),
    })
    // eslint-disable-next-line no-console
    console.log('Error getting cookies')
  }

  return undefined
}

export const getGtmUserConsentLevel = () => {
  const consentCookies = getConsentCookies()

  return {
    ad_storage: consentCookies ? consentCookies.marketing : false,
    analytics_storage: consentCookies ? consentCookies.statistic : false,
    functionality_storage: true,
    personalization_storage: consentCookies ? consentCookies.marketing : false,
    security_storage: true,
  }
}

export const getGtmEnvironment = () =>
  process.env.NODE_ENV === 'develop' ? 'dev' : process.env.NUXT_ENV_GTM_ENV

export const getGtmLeadId = () => {
  let leadId = Cookies.get('_gtm_lead_id')
  if (!leadId) {
    leadId = uuid.v1()
    Cookies.set('_gtm_lead_id', leadId, {
      domain: location.host.split('.').splice(1).join('.'),
    })
  }
  return leadId
}

export const getServerConsents = async (vueInstance) => {
  const consentsResponse = await vueInstance.$apiCall(
    '/wsc/shared/consents/getConsents',
    {
      method: 'get',
      sendAuth: true,
    }
  )
  if (consentsResponse && consentsResponse.status === 200) {
    // TODO: Remove mobileAccessConsent from response when it's not needed and it's fetched from BE
    return { ...consentsResponse.data }
  } else {
    // eslint-disable-next-line no-console
    console.log('Error getting BE consents')
  }

  return null
}

export const setServerConsents = async (vueInstance, consentData) => {
  const user = getUser()
  if (user) {
    const options = {
      data: consentData,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      sendAuth: true,
    }

    return await vueInstance.$apiCall(
      '/wsc/shared/consents/setConsents',
      options
    )
  }
  return undefined
}

export const afterLoginCallback = async (vueInstance, user) => {
  let consents = {} // for final (client cookies and server data merged) consent object
  const serverConsents = await getServerConsents(vueInstance)
  if (serverConsents) {
    consents = {
      ...serverConsents,
    }
    if (
      serverConsents.marketingCookiesConsentLastChanged &&
      serverConsents.statisticsCookiesConsentLastChanged
    ) {
      // set consent cookies on client using server values if not set already
      const acceptedCount = Object.values([
        serverConsents.statisticsCookiesConsent,
        serverConsents.marketingCookiesConsent,
      ]).filter(Boolean).length

      const clientConsents = getConsentCookies()

      if (
        clientConsents === undefined ||
        serverConsents.marketingCookiesConsent !== clientConsents.marketing ||
        serverConsents.statisticsCookiesConsent !== clientConsents.statistic
      ) {
        // set signal for CookieBar
        process.nextTick(() => Vue.prototype.$setSaveContent('server'))

        trackingPush({
          event: 'action.viewMergeAccountPopup.cmp',
          action: {
            viewMergeAccountPopup: {
              cmp: {
                deviceId: getCookieId(),
              },
            },
          },
        })

        setConsentCookies(
          {
            statistic: serverConsents.statisticsCookiesConsent,
            marketing: serverConsents.marketingCookiesConsent,
          },
          acceptedCount === 2 ? 365 : 182
        )
      } else {
        // set server consents using client values if present
        const clientConsents = getConsentCookies()
        if (clientConsents) {
          consents = {
            ...consents,
            ...clientConsents,
          }

          // set signal for CookieBar
          process.nextTick(() => Vue.prototype.$setSaveContent('client'))

          await setServerConsents(vueInstance, {
            ...serverConsents,
            // technicalCookiesConsent: true,
            thirdPartyContentConsent: clientConsents.thirdParty,
            marketingCookiesConsent: clientConsents.marketing,
            statisticsCookiesConsent: clientConsents.statistic,
          })
        }
      }
    }
    if (consents) vueInstance.$store.commit('setConsents', consents)
  }
}

export const getExternalBannerData = async (vueInstance, exponeaID) => {
  if (!exponeaID) {
    return
  }

  const options = {
    method: 'post',
    data: { exponeaID },
    sendAuth: true,
  }
  const response = await vueInstance.$apiCall(
    '/wsc/shared/getExternalBanner',
    options
  )

  if (
    response &&
    response.status === 200 &&
    response.data?.slider?.length > 0
  ) {
    return response.data.slider
  }
  return undefined
}

export const getExternalBannerDataData = async (vueInstance, exponeaDataID) => {
  if (!exponeaDataID) {
    return
  }

  const options = {
    method: 'post',
    data: { exponeaID: exponeaDataID },
    sendAuth: true,
  }
  const response = await vueInstance.$apiCall(
    '/wsc/shared/getExternalBannerData',
    options
  )

  if (response && response.status === 200) {
    return response.data
  }
  return undefined
}

export const getESimStatus = async (vueInstance) => {
  try {
    const response = await vueInstance.$apiCall('/wsc/shared/esim/iccid', {
      method: 'get',
      sendAuth: true,
    })

    return {
      esim: response?.data?.esim,
      status: response?.data?.esimStatus,
      qrCode: response?.data?.qrCode,
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    process.env.NODE_ENV === 'development' && console.log(e)
  }

  return undefined
}

export const getCookieId = () => {
  const idCookies = localStorage.getItem('sam-id') || uuid.v4()
  localStorage.setItem('sam-id', idCookies)
  return idCookies
}

export const getPaymentMethodName = (paymentMethod) => {
  console.log(paymentMethod)
  switch (paymentMethod) {
    case 'c':
      return 'platebni_karta'
    case 'ap':
      return 'google_pay'
    case 'jp':
      return 'apple_pay'
    case 'cs':
      return 'ceska_sporitelna'
    case 'cb':
      return 'csob'
    case 'pf':
      return 'fio_banka'
    case 'kb':
      return 'komercni_banka'
    case 'pg':
      return 'moneta'
    case 'mp':
      return 'mbank'
    case 'rf':
      return 'raiffeisen_bank'
    case 'uc':
      return 'unicredit_bank'
    default:
      return 'dobirka'
  }
}
