<template>
  <div v-if="helpPage.length !== 0" v-responsive.sm.xs class="u-help-icon">
    <a v-b-modal="`open-help-window`" href="#"
      ><img-svg src="app-nav-help.svg"></img-svg
    ></a>

    <b-modal id="open-help-window" modal-footer="false" size="lg" hide-footer>
      <template #modal-header>
        <ModalHeader :modal-id="`open-help-window`"></ModalHeader>
      </template>

      <CollapseHelp
        collapse-help-title="Nápověda"
        :help-data="helpPage"
      ></CollapseHelp>
    </b-modal>
  </div>
</template>

<script>
import ImgSvg from './common/imgSvg'
import ModalHeader from '~/components/ModalHeader.vue'
import CollapseHelp from '~/components/c-collapse--help.vue'

export default {
  name: 'HowItWorks',
  components: {
    ImgSvg,
    ModalHeader,
    CollapseHelp,
  },
  props: {
    type: {
      type: String,
      default: () => '',
      required: false,
    },
  },

  computed: {
    helps() {
      return [
        {
          pages: [this.$sysPage('overview'), this.$sysPage('packages')],
          texts: [
            {
              title: 'Co je služba DENNÍ?',
              description:
                'Pokud nemáte aktivní žádný datový balíček, naskočí vám automaticky služba DENNÍ. Stojí 25 Kč/den a začne se účtovat pouze v případě, že data skutečně čerpáte.',
            },
            {
              title: 'Co je služba BEZ DENNÍCH?',
              description:
                'Pokud službu DENNÍ nechcete, nastavte si prosím službu BEZ DENNÍCH DAT. Zachováte si tím možnost nákupu dalšího datového balíčku, až o něj budete mít zájem.',
            },
            {
              title: 'Co je služba BEZ DAT?',
              description:
                'Služba je určena pro zákazníky, kteří nechtějí čerpat data vůbec.',
            },
            {
              title: 'Mohu využívat více služeb/balíčků v daném měsíci?',
              description:
                'Můžete čerpat v případě odlišných služeb (volání, SMS, data). Nemůžete čerpat balíčky stejných služeb (např. 1 GB a 5 GB). Balíčky se nesčítají, nový balíček ruší předchozí.',
            },
          ],
        },
        {
          pages: [this.$sysPage('overview'), this.$sysPage('rewards')],
          texts: [
            {
              title: 'Jak změnit nastavenou Odměnu za dobití?',
              description:
                'Před dobitím kreditu vyberte Odměnu, kterou chcete využívat. Dobijte si alespoň za 300 Kč a svou Odměnu můžete využívat celý následující měsíc.',
            },
            {
              title: 'Mohu využívat více Odměn za dobití?',
              description:
                'Ne. Aktivně můžete využívat vždy pouze jednu Odměnu za dobití.',
            },
          ],
        },
        {
          pages: [this.$sysPage('packages')],
          texts: [
            {
              title: 'Co se stane po vyčerpání datového balíčku?',
              description:
                'Pokud objem dat vyčerpáte před uplynutím doby, zpomalí se rychlost na 32 kb/s. Touto rychlostí můžete datovat do konce platnosti balíčku nebo do koupě nového. Koupí nového balíčku opět naskočíte na standardní plnou rychlost připojení.',
            },
            {
              title: 'Jak funguje dokup dat u Šťastných tarifů?',
              description:
                'Dokup dat je možný kdykoli během zúčtovacího období, v jeden okamžik je možné mít ale jen jeden platný datový balíček. Dokoupený balíček platí od okamžiku objednání do konce zúčtovacího období nebo do vyčerpání objemu dokupu, avšak nejpozději do konce zúčtovacího období.',
            },
          ],
        },
        {
          pages: [this.$sysPage('rating')],
          texts: [
            {
              title: 'Co znamená Hodnocení v Samoobsluze?',
              description:
                'Prostřednictvím Hodnocení nám můžete sdělit, jak jste s naší Samoobsluhou spokojeni. V případě spokojenosti dávejte vyšší číslo (více bodů). Děkujeme vám za všechny podněty a připomínky, jsou pro nás vodítkem pro další zlepšení.',
            },
          ],
        },
        {
          pages: [this.$sysPage('overview'), this.$sysPage('reports')],
          texts: [
            {
              title: 'Jak se účtuje volání u SAZKAmobilu?',
              description:
                'Hovory účtujeme po první provolané minutě po vteřinách. Stejně účtujeme hovory také v roamingu, pouze pro země Zóny 2 a 3 platí účtování po minutách.',
            },
            {
              title: 'Na jaké síti SAZKAmobil funguje?',
              description:
                'Fungujeme v rámci sítě Vodafone, máme tedy stejné pokrytí a stejnou kvalitu signálu jako tento mobilní operátor. Aktuální mapu pokrytí najdete na našich webových stránkách.',
            },
          ],
        },
        // {
        //   pages: [this.$sysPage('rewardsWheel')],
        //   texts: ((this.rewardsWheel && this.rewardsWheel.faq_categories) || [])
        //     .map((faqCategory) =>
        //       faqCategory.faq.map(({ title, content }) => ({
        //         title,
        //         description: content,
        //       }))
        //     )
        //     .flat(),
        // },
      ]
    },
    helpPage() {
      // pokud jiz mame zadano
      if (this.helps && this.type) {
        const type = this.type
        let res = []
        this.helps
          .filter(function (item) {
            return item.pages.includes(type)
          })
          .forEach((element) => {
            res = res.concat(element.texts)
          })
        return res
      }
      return []
    },
    rewardsWheel() {
      return this.$config.strapiData.rewardsWheel?.data?.attributes
    },
  },
}
</script>

<style lang="scss">
.u-help-icon {
  position: absolute;
  right: 15px;
  transform: scale(1.5);

  .body--is-app & {
    order: 2;
    position: initial;
    right: initial;
    margin-left: 15px;
  }
}
</style>
