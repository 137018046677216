<template>
  <validation-provider
    v-slot="{ errors, validated }"
    :rules="rules"
    :vid="vid"
    :name="name"
  >
    <slot :state="isValid(errors, validated)" :error="errors[0]"></slot>
  </validation-provider>
</template>

<script>
import { ValidationProvider, extend, setInteractionMode } from 'vee-validate'
import {
  required,
  email,
  numeric,
  min_value as minValue,
  max_value as maxValue,
} from 'vee-validate/dist/rules'

setInteractionMode('eager')

// validation rules
extend('required', {
  ...required,
  message: 'Tato položka je povinná.',
})

extend('passwordCheck', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'Zadané hesla se musí shodovat.',
})

extend('password', {
  validate(value) {
    const pattern = /^\d{6}/
    return pattern.test(value)
  },
  message: 'Heslo musí mít 6 číselných znaků',
})

extend('requiredOneOf', {
  params: ['targetValue'],
  validate(value, { targetValue }) {
    return (
      (!!value && value.length > 0) || (!!targetValue && targetValue.length > 0)
    )
  },
  computesRequired: true,
  message: 'Vyplňte alespoň jedno z polí {_field_} a {targetValue}.',
})

extend('email', {
  ...email,
  message: 'E-mail není zadán ve správném tvaru.',
})

extend('phone', {
  validate(value) {
    const pattern = /^\+420[\s]?([0-9]{3}[\s]?[0-9]{3}[\s]?[0-9]{3})$/
    return pattern.test(value)
  },
  message: 'Telefonní číslo není zadáno ve správném tvaru (+ 420 704 705 706).',
})

extend('numeric', {
  ...numeric,
  message: 'Hodnota musí být celé kladné číslo.',
})

extend('personalIdNumber', {
  validate(value) {
    return (
      !isNaN(parseInt(value)) &&
      (value.length === 9 || (value.length === 10 && Number(value) % 11 === 0))
    )
  },
  message: 'Zadané rodné číslo je nevalidní.',
})

extend('idNumber', {
  validate(value) {
    return !isNaN(parseInt(value)) && value.toString().length === 9
  },
  message: 'Zadané číslo OP je nevalidní.',
})

extend('isTrue', {
  validate(value) {
    return value
  },
  message: 'Tato položka je povinná.',
})

extend('nameSurname', {
  validate(value) {
    const pattern = /^[^\d^\s]+\s[^\d^\s]+/
    return pattern.test(value)
  },
  message: 'Zadejte své jméno a příjmení.',
})

extend('streetNo', {
  validate(value) {
    const pattern = /^[\d]+[\w]?/
    return pattern.test(value)
  },
  message: 'Zadejte ve tvaru 12a',
})

extend('zip', {
  validate(value) {
    const pattern = /^\d{3}\s?\d{2}/
    return pattern.test(value)
  },
  message: 'Zadejte psč ve tvaru 123 45.',
})

extend('min_value', {
  ...minValue,
  params: ['min'],
  message: 'Minimální povolená hodnota je {min}.',
})

extend('max_value', {
  ...maxValue,
  params: ['max'],
  message: 'Maximální povolená hodnota je {max}.',
})

extend('account_prefix', {
  validate(value) {
    const pattern = /^\d{0,6}$/
    return pattern.test(value)
  },
  message: 'Číslo prefixu účtu může mít až 6 číslic.',
})

extend('account_number', {
  validate(value) {
    const pattern = /^\d{2,10}$/
    return pattern.test(value)
  },
  message: 'Číslo účtu může mít 2 až 10 číslic.',
})

extend('bank_id', {
  validate(value) {
    const pattern = /^\d{4}/
    return pattern.test(value)
  },
  message: 'Číslo banky je čtyřmístné.',
})

extend('str_length', {
  params: ['length'],
  validate(value, { length }) {
    return value.length === parseInt(length)
  },
  message: 'Počet znaků musí být {length}',
})

extend('notIn', {
  params: ['list'],
  validate(value, { list }) {
    return !list.includes(value)
  },
  message: 'Zadaná hodnota je již použita.',
})

export default {
  name: 'Validation',
  components: {
    ValidationProvider,
  },
  props: {
    rules: {
      type: [String, Object],
      default: null,
      required: true,
    },
    vid: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  methods: {
    // TODO nastavit state na null pokud je input prazdny ?
    isValid(errors, validated) {
      return errors.length > 0 ? false : validated ? true : null
    },
  },
}
</script>
