<template>
  <div>
    <Header
      :title="title"
      :pwa-title="pwaTitle"
      :pwa-class="pwaClass"
      :has-back-arrow-on-app="hasBackArrowOnApp"
      :type="type"
    ></Header>

    <slot />

    <pwdless-login-info-modal
      v-if="$uvpaaAvailable && userData"
    ></pwdless-login-info-modal>

    <appMenu v-if="!isWeb && userAuthToken"></appMenu>
    <Footer v-if="isWeb" :style-mode="footerStyle"></Footer>
  </div>
</template>

<script>
import Footer from '~/components/Footer.vue'
import Header from '~/components/Header.vue'
import appMenu from '@/components/appMenu'
import { getAuthToken } from '@/config/common'
import { parseJwt } from '@/mixins/parseJwt'
import pwdlessLoginInfoModal from '@/components/pwdlessLoginInfoModal'

export default {
  name: 'Layout',
  components: {
    Footer,
    Header,
    appMenu,
    pwdlessLoginInfoModal,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    pwaClass: {
      type: String,
      default: null,
    },
    pwaTitle: {
      type: String,
      default: null,
    },
    footerStyle: {
      type: String,
      default: 'dark',
    },
    hasBackArrowOnApp: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      DEF_INTERVAL_TIMEOUT: 240000,
      DEF_INTERVAL_TIMEOUT_COUNT: 3,
      userAuthToken: false,
      isWeb: null,
      styleMode: this.footerStyle,
      userData: false,
      intervalTopMessage: null,
      intervalTopMessageTime: null,
    }
  },
  head() {
    const meta = [
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
      {
        'http-equiv': 'Content-Security-Policy',
        content: 'upgrade-insecure-requests',
      },
    ]
    const bodyAttrs = {
      'data-layout': 'default',
      class: [],
    }

    if (!this.isWeb) {
      meta.push(
        {
          name: 'mobile-web-app-capable',
          content: 'yes',
        },
        {
          name: 'apple-mobile-web-app-capable',
          content: 'yes',
        }
      )
      bodyAttrs.class.push('body--is-app')
    }
    return {
      meta,
      bodyAttrs,
      title: `${this.title} - SAZKAmobil Samoobsluha`,
    }
  },
  mounted() {
    this.userAuthToken = getAuthToken()
    if (this.userAuthToken) {
      this.userData = parseJwt(this.userAuthToken)
    }

    if (
      this.$store.state.webOrApp &&
      this.$store.state.webOrApp === 'web' &&
      !(
        this.$store.state.appPlatform && this.$store.state.appPlatform === 'ios'
      )
    ) {
      this.isWeb = true
    }

    this.intervalTopMessageCount = 0
    const selfVue = this
    this.intervalTopMessage = window.setInterval(function () {
      selfVue.loadTopPageMessage()
    }, this.DEF_INTERVAL_TIMEOUT)
  },
  methods: {
    async loadTopPageMessage() {
      this.intervalTopMessageCount++
      if (this.intervalTopMessageCount > this.DEF_INTERVAL_TIMEOUT_COUNT) {
        window.clearInterval(this.intervalTopMessage)
        return
      }

      await this.$apiCall('/wsc/login/getTopPageMessage', { method: 'get' })
    },
  },
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.body--wheel-of-fortune-bar-visible {
  padding-bottom: 4.5rem;

  @include media-breakpoint-up(md) {
    padding-bottom: 6rem;
  }

  &.body--is-app {
    padding-bottom: 7.5rem;

    @include media-breakpoint-up(md) {
      padding-bottom: 10rem;
    }
  }
}
</style>
