<template>
  <p class="u-menu-icons" :data-component="componentName">
    <!-- TODO: Vyměnit `:key` -->
    <a
      v-for="(item, index) in menu"
      :key="index"
      :class="'u-menu-icons__link u-menu-icons__link--' + item.code"
      :href="item.href"
      :target="item.target"
      :title="item.title"
    >
      <img-svg
        class="u-menu-icons__icon"
        :src="`u-menu-icons__link--${item.code}.svg`"
      ></img-svg>
    </a>
  </p>
</template>

<script>
import ImgSvg from './common/imgSvg'

export default {
  name: 'FooterMenuIcons',
  components: {
    ImgSvg,
  },
  props: {
    menuData: { type: Array, required: true },
  },
  data() {
    return {
      componentName: this.$options.name,
      menu: this.menuData,
    }
  },
}
</script>

<style lang="scss">
.u-menu-icons {
  padding: 0 map-get($spacers, 4);
  margin: 0 0 map-get($spacers, 4);

  &__icon {
    path {
      fill: theme-color('primary');
    }
  }

  &__link {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 0.5rem 0.75rem;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;

    &:hover {
      path {
        fill: theme-color('primary-2');
      }
    }
  }
}
</style>
