<template>
  <div>
    <header
      :class="`o-header ${isLogged ? 'o-header--logged' : ''}`"
      :data-component="$options.name"
    >
      <div class="u-header-logo">
        <nuxt-link
          v-if="!isApp"
          class="u-header-logo__link"
          :to="isLogged ? $sysPage('overview') : $sysPage('login')"
        >
          <img
            width="65"
            height="26"
            src="~/assets/images/logo_sazkamobil_medium.png"
          />
        </nuxt-link>
        <a v-else-if="!hasBackArrowOnApp" class="u-header-logo__link">
          <img
            width="65"
            height="26"
            src="~/assets/images/logo_sazkamobil_medium.png"
          />
        </a>
      </div>

      <a
        v-if="isApp && hasBackArrowOnApp"
        href="#"
        class="u-header-back"
        @click.prevent="$router.back()"
      >
        <img-svg src="arrow-left-navigation.svg"></img-svg>
      </a>

      <h1
        v-if="isApp"
        :class="[
          'u-header-title',
          pwaClass ? pwaClass : null,
          isLogged ? null : 'u-header-title-login',
        ]"
      >
        {{ pwaTitle ? pwaTitle : title }}
      </h1>

      <Help v-if="isApp" :type="type"></Help>

      <div v-if="!isApp" class="u-header-nav">
        <ul v-if="user.subscribtionType == 'PREPAID'" class="nav">
          <li
            v-for="(page, index) in prepaidPages"
            :key="index"
            class="nav-item"
            @click="$gtmEvent('menu klik', 'WSC', page.title)"
          >
            <nuxt-link
              :class="[page.koloOdmen ? 'nav-link nav-link-green' : 'nav-link']"
              :to="page.href"
              >{{ page.title }}</nuxt-link
            >
          </li>
        </ul>
        <ul v-else-if="user.subscribtionType == 'POSTPAID'" class="nav">
          <li
            v-for="(page, index) in postpaidPages"
            :key="index"
            class="nav-item"
            @click="$gtmEvent('menu klik', 'WSC', page.title)"
          >
            <nuxt-link class="nav-link" :to="page.href">{{
              page.title
            }}</nuxt-link>
          </li>
        </ul>
      </div>

      <div v-if="isLogged" class="u-header-mobile">
        <div v-if="!isApp" class="u-header-mobile__avatar">
          <span
            class="u-header-mobile__number u-header-mobile__number--nickname"
            >{{ loggedNickname }}</span
          >
          <span class="u-header-mobile__number">{{
            user.sub | userNumber
          }}</span>
        </div>
        <ul class="nav">
          <li class="nav-item">
            <span
              v-b-toggle.collapse-nav
              class="nav-link"
              aria-label="Zobrazit / skrýt menu"
              @click="$toggleBodyModalClass"
            >
              <div class="toggle-icons">
                <img-svg
                  :class="['toggle-icons__icon', 'toggle-icons__icon--close']"
                  width="30"
                  height="30"
                  src="o-header__item-link--toggler.svg"
                ></img-svg>
                <img-svg
                  :class="['toggle-icons__icon', 'toggle-icons__icon--open']"
                  width="30"
                  height="30"
                  src="o-header__item-link--toggler-active.svg"
                ></img-svg>
              </div>
            </span>
          </li>
        </ul>
      </div>

      <div v-if="!isApp && isLogged" class="u-header-desktop">
        <nuxt-link
          class="btn btn-warning"
          :to="$sysPage('logout')"
          @click="$gtmEvent('menu klik', 'WSC', 'Odhlásit')"
        >
          <img-svg
            width="30"
            height="30"
            src="o-header__item-link--user.svg"
          ></img-svg>
          Odhlásit
        </nuxt-link>
        <span class="u-header-desktop__number"
          >+420 {{ user.sub | userNumber }}</span
        >
        <span
          class="u-header-desktop__number u-header-desktop__number--nickname"
          >{{ loggedNickname }}</span
        >
      </div>

      <HeaderMobileNav v-if="user"></HeaderMobileNav>

      <div
        v-if="!isApp && isLogged && !hideLangHelp"
        v-responsive.lg.xl
        class="u-lang-help"
      >
        <span v-b-modal="`modal-lang-help`" class="u-flag">
          <img-svg width="20" height="20" :src="strapiLangHelpIcon"></img-svg>
        </span>
        <span class="u-flag-close-button" @click="setHideLangHelp">
          <img-svg
            class="u-flag-close-button-img"
            src="modal-close.svg"
          ></img-svg>
        </span>
      </div>

      <b-modal
        id="modal-lang-help"
        hide-footer
        hide-header
        modal-footer="false"
        size="lg"
        title="title"
      >
        <div class="container text-left" v-html="wscStrapi.langHelp"></div>
      </b-modal>
    </header>

    <b-alert
      :show="isTopPageMessageVisible"
      :variant="topPageMessageVariant"
      class="text-center info-message"
    >
      {{ topPageMessageText }}
    </b-alert>

    <div v-if="isApp && isLogged" class="u-header-app-user">
      <a
        href="#"
        class="u-header-app-user__item"
        @click.prevent="showUserSwitchModal"
      >
        <span>{{ user.sub | userNumber }}</span>
        <img-svg
          src="arrow-down.svg"
          width="16"
          class="u-header-app-user__caret"
        ></img-svg>
      </a>
    </div>
  </div>
</template>

<script>
import ImgSvg from './common/imgSvg'
import HeaderMobileNav from '~/components/HeaderMobileNav'
import { getAuthToken, getUser, getSavedUsersList } from '~/config/common'
import Help from '~/components/Help'

export default {
  name: 'Header',
  components: {
    ImgSvg,
    HeaderMobileNav,
    Help,
  },
  props: {
    pwaClass: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    pwaTitle: {
      type: String,
      default: null,
    },
    hasBackArrowOnApp: {
      type: Boolean,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isApp: false,
      loggedNickname: null,
      prepaidPages: [
        {
          title: 'Přehled',
          href: this.$sysPage('overview'),
        },
        {
          title: 'Odměny',
          href: this.$sysPage('rewards'),
        },
        {
          title: 'Dobíjení',
          href: this.$sysPage('recharge'),
        },
        {
          title: 'Výpisy',
          href: this.$sysPage('reports'),
        },
        {
          title: 'Balíčky',
          href: this.$sysPage('packages'),
        },
        {
          title: 'Služby',
          href: this.$sysPage('services'),
        },
        {
          title: 'Hodnocení',
          href: this.$sysPage('rating'),
        },
        {
          title: 'Přenos čísla',
          href: this.$sysPage('numberTransmission'),
        },
        {
          title: 'Osobní',
          href: this.$sysPage('personalData'),
        },
        // {
        //   title: 'Kolo odměn',
        //   href: this.$sysPage('rewardsWheel'),
        //   koloOdmen: true,
        // },
      ],
      postpaidPages: [
        {
          title: 'Přehled',
          href: this.$sysPage('overview'),
        },
        {
          title: 'Vyúčtování',
          href: this.$sysPage('postpaidPayment'),
        },
        {
          title: 'Výpisy',
          href: this.$sysPage('reports'),
        },
        {
          title: 'Balíčky',
          href: this.$sysPage('packages'),
        },
        {
          title: 'Služby',
          href: this.$sysPage('services'),
        },
        {
          title: 'Hodnocení',
          href: this.$sysPage('rating'),
        },
        {
          title: 'Přenos čísla',
          href: this.$sysPage('numberTransmission'),
        },
        {
          title: 'Osobní',
          href: this.$sysPage('personalData'),
        },
      ],
      user: {
        subscribtionType: '',
      },
      hideLangHelp: false,
    }
  },
  computed: {
    isLogged() {
      return (
        this.user &&
        this.user.subscribtionType &&
        (this.user.subscribtionType === 'POSTPAID' ||
          this.user.subscribtionType === 'PREPAID')
      )
    },
    isTopPageMessageVisible() {
      return this.$topPageMessage !== null && this.$topPageMessage.showWSC
    },
    topPageMessageVariant() {
      return this.$topPageMessage !== null && this.$topPageMessage.typeWSC
    },
    topPageMessageText() {
      return this.$topPageMessage !== null && this.$topPageMessage?.contentWSC
    },
    wscStrapi() {
      return {
        id: this.$config.strapiData.wsc?.data?.id,
        ...this.$config.strapiData.wsc?.data?.attributes,
      }
    },
    strapiLangHelpIcon() {
      return (
        'flag--' +
        (this.$config.strapiData.wsc?.data?.attributes?.langHelpIcon ?? '') +
        '.svg'
      )
    },
  },
  mounted() {
    const authToken = getAuthToken()
    if (authToken) {
      this.user = getUser()
      const matchedUser = (getSavedUsersList(this.user.accountId) ?? []).filter(
        (userFromLocalStorage) => {
          const loggedUsername = getUser() && getUser().sub
          const formattedUsername =
            userFromLocalStorage &&
            userFromLocalStorage.username &&
            userFromLocalStorage.username.replace(/ +/g, '').substring(1)
          return loggedUsername === formattedUsername
        }
      )
      this.loggedNickname = matchedUser[0] && matchedUser[0].nickname
    }
    if (
      (this.$store.state.webOrApp && this.$store.state.webOrApp === 'app') ||
      (this.$store.state.appPlatform && this.$store.state.appPlatform === 'ios')
    ) {
      this.isApp = true
    }

    this.hideLangHelp =
      !this.wscStrapi.langHelpEnable ||
      window.localStorage.getItem('hideLangHelp')
  },
  destroyed() {
    this.$toggleBodyModalClass()
  },
  methods: {
    showUserSwitchModal() {
      this.$store.commit('setSwitchUserModalActive', 'accSwitch')
    },
    setHideLangHelp() {
      this.hideLangHelp = true
      window.localStorage.setItem('hideLangHelp', this.hideLangHelp)
    },
  },
}
</script>

<style lang="scss">
.u-lang-help {
  position: absolute;
  right: 15px;
  top: 135px;
  display: block;
}
.u-flag {
  width: 1.75rem;
  height: 1.75rem;
  overflow: hidden;
  border: 0.25rem solid $off-white;
  border-radius: 50%;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }

  @include media-breakpoint-up(lg) {
    width: 2.4rem;
    height: 2.4rem;
  }

  &-close-button {
    display: none;
  }

  &-close-button-img {
    position: absolute;
    right: -5px;
    top: -2px;
    z-index: 10;
    width: 15px;
    cursor: pointer;
  }
}

.u-lang-help:hover .u-flag-close-button {
  display: block;
}

.o-header {
  position: relative;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: $header-height;
  padding: 0;
  background-color: $gray-100;
  margin-bottom: $spacer;

  &--logged {
    height: $header-height + $spacer;

    @include media-breakpoint-up(lg) {
      height: $header-height-lg + $spacer;
    }

    .body--is-app & {
      display: flex;
    }
  }

  &--form {
    @include media-breakpoint-down(md) {
      flex-direction: row-reverse;
    }
  }

  @include media-breakpoint-up(lg) {
    height: 5rem;
  }

  .body--is-app & {
    position: fixed;
    height: $header-height;
    background-color: $white;
    border-bottom: solid $gray-200 0.125rem;
    margin-bottom: 0;
  }
}

.u-header-logo {
  margin: 0;

  .body--is-app & {
    order: 4;
  }

  &__link {
    display: block;
    padding: $spacer;

    .body--is-app & {
      background-color: white;
    }

    @include media-breakpoint-up(lg) {
      svg {
        width: 7rem;
        height: 2.8rem;
      }
    }

    @include media-breakpoint-up(xl) {
      padding: $spacer 3.125rem;
    }
  }
}

.u-header-back {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  padding: 0.9rem 0.5rem;
}

.u-header-nav {
  display: none;
  flex: 0 1 100%;
  height: 100%;

  @include media-breakpoint-up(md) {
    display: block;
  }

  .nav {
    flex-wrap: nowrap;
    height: 100%;

    @include media-breakpoint-up(xl) {
      .nav {
        align-items: stretch;
        justify-content: space-between;
      }
    }
  }

  .nav-item {
    position: relative;
    flex: 1;
    height: 100%;
    font-weight: $font-weight-bold;
    font-size: 0.8125rem;
    text-align: center;
    text-transform: uppercase;

    @include media-breakpoint-up(xl) {
      .nav-item {
        flex: auto;
      }
    }

    &.active::after {
      content: ' ';
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      display: block;
      height: 15px;
      background-color: blue;
    }
  }

  .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: $spacer * 0.5;
    color: $dark-slate-blue-two;

    @include media-breakpoint-up(xl) {
      .nav-link {
        letter-spacing: 0.06rem;
      }
    }

    &:hover {
      color: theme-color('primary-2');
    }

    &.active::after {
      content: '';
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      display: block;
      height: 15px;
      background-color: theme-color('primary');
    }
  }
}

.u-header-title {
  position: absolute;
  width: 100%;
  margin: 0;
  font-weight: bold;
  font-size: 0.8125rem;
  text-align: center;
  text-transform: uppercase;

  .body--is-app & {
    position: initial;
    order: 3;
  }
}

.u-header-title-login {
  margin-left: 1rem;
  text-align: left;
  padding-left: 30px;
}

.u-header-desktop {
  position: relative;
  display: none;
  height: 100%;
  padding-left: $spacer;

  @include media-breakpoint-up(xl) {
    padding-left: $spacer * 2;
  }

  .btn {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 3.8rem 0 5.4rem;
    margin-left: $spacer * 0.5;
    font-size: 0.8rem;
    text-transform: uppercase;
    border: 0 none;
    letter-spacing: 0.06rem;

    svg {
      position: absolute;
      top: 50%;
      left: 3rem;
      transform: translateY(-50%);
    }
  }

  @include media-breakpoint-up(lg) {
    display: block;
  }

  &__number {
    position: absolute;
    top: 100%;
    right: 0;
    left: $spacer * 1.5;
    padding: 0.5rem 0;
    font-weight: $font-weight-bold;
    font-size: 0.9375rem;
    line-height: 1.9;
    text-align: center;
    background-color: $yellow;
    // prettier-ignore
    clip-path:
      polygon(
        100% 0,
        100% 100%,
        1.5rem 100%,
        0% calc(100% - 1.5rem),
        0 0
      );

    &--nickname {
      top: 80%;
      padding: 0;
      line-height: 1.4;
    }

    @include media-breakpoint-up(xl) {
      left: $spacer * 2.5;
    }
  }
}

.u-header-mobile {
  display: flex;
  align-items: center;

  .body--is-app & {
    order: 1;
  }

  &__avatar {
    display: flex;
    flex-direction: column-reverse;
    text-align: right;
  }

  &__number {
    margin-right: $spacer;
    font-weight: $font-weight-bold;
    font-size: 0.9375rem;
    line-height: 1.2;
    color: $blue;

    &--nickname {
      font-weight: normal;
    }
  }

  .nav {
    flex-wrap: nowrap;
  }

  // stylelint-disable-next-line no-descending-specificity
  .nav-link {
    position: relative;
    padding: $spacer 0.35rem;

    @include media-breakpoint-up(sm) {
      padding: $spacer ($spacer * 0.5);
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 1;
    background: $white;
    transform: translate(-50%, -50%);
    transition: $transition-base;
  }

  .nav-link.collapsed &__icon {
    opacity: 0;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.u-header-app-user {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 0.8125rem;
  text-align: center;
  color: $primary;

  &__item {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
  }

  &__caret {
    position: absolute;
    right: -18px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.toggle-icons {
  position: relative;
  width: 30px;
  height: 30px;

  &__icon {
    position: absolute;
    top: 0;
    transition: 0.2s opacity;

    &--close {
      opacity: 1;
    }

    &--open {
      opacity: 0;
    }

    .modal-open & {
      &--close {
        opacity: 0;
      }

      &--open {
        opacity: 1;
      }
    }
  }
}

.nav-link-green {
  color: #46c152 !important;
}

.nav-link-green.active::after {
  background-color: #46c152 !important;
}

.info-message {
  @include media-breakpoint-up(md) {
    padding-left: 120px;
    padding-right: 120px;
  }

  @include media-breakpoint-up(lg) {
    padding-left: 250px;
    padding-right: 250px;
  }
}
</style>
