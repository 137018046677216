import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import {
  getConsentCookies,
  getServerConsents,
  getUser,
  setConsentCookies,
} from '@/config/common'

export default async ({ app, store }, inject) => {
  let swRegistration, swUpdate

  const clientCookiesConsents = getConsentCookies()
  store.commit('setConsents', clientCookiesConsents)
  const user = getUser()
  if (user) {
    const serverConsents = await getServerConsents(app)
    if (serverConsents) {
      store.commit('setConsents', serverConsents)

      if (
        serverConsents.statisticsCookiesConsentLastChanged &&
        serverConsents.marketingCookiesConsentLastChanged
      ) {
        const acceptedCount = Object.values([
          serverConsents.statisticsCookiesConsent,
          serverConsents.marketingCookiesConsent,
        ]).filter(Boolean).length
        setConsentCookies(
          {
            statistic: serverConsents.statisticsCookiesConsent,
            marketing: serverConsents.marketingCookiesConsent,
          },
          acceptedCount === 2 ? 365 : 182
        )
      }
    }
  }
  const topPageMessageResponse = await app.$apiCall(
    '/wsc/login/getTopPageMessage',
    { method: 'get' }
  )
  let topPageMessage = null
  if (
    topPageMessageResponse &&
    topPageMessageResponse.status === 200 &&
    topPageMessageResponse.data
  ) {
    topPageMessage = topPageMessageResponse.data
  }
  inject('topPageMessage', topPageMessage)

  const url = new URL(window.location.href)
  const urlParamAppPlatform =
    url.searchParams.get('app_platform') ||
    window.localStorage.getItem('appPlatform')
  const urlParamAppType =
    url.searchParams.get('app_type') ||
    window.sessionStorage.getItem('urlParamAppType')

  let webOrApp =
    window.matchMedia('(display-mode: browser)').matches ||
    window.matchMedia('display-mode: browser').matches
      ? 'web'
      : 'app'

  if (
    (urlParamAppType && urlParamAppType === 'pwa') ||
    (urlParamAppPlatform && urlParamAppPlatform === 'ios')
  ) {
    webOrApp = 'app'
  }

  inject('webOrApp', webOrApp)

  // on app - platform auth (biometric) is available check and set
  let uvpaaAvailable = false

  try {
    if (
      urlParamAppPlatform !== 'ios' &&
      webOrApp === 'app' &&
      window.PublicKeyCredential &&
      window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable
    ) {
      uvpaaAvailable =
        await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    process.env.NODE_ENV === 'development' && console.log(e)
    uvpaaAvailable = false
  }
  inject('uvpaaAvailable', uvpaaAvailable)

  store.commit('setWebOrApp', webOrApp)
  store.commit('setAppPlatform', urlParamAppPlatform)
  if (urlParamAppPlatform) {
    window.localStorage.setItem('appPlatform', urlParamAppPlatform)
  }
  if (urlParamAppType) {
    window.sessionStorage.setItem('urlParamAppType', urlParamAppType)
  }

  // service worker
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    await navigator.serviceWorker.register('/service-worker.js', {
      updateViaCache: 'none',
    })
    if (webOrApp === 'app') {
      // pwa sw
      swRegistration = await navigator.serviceWorker.register(
        '/app-service-worker.js',
        {
          updateViaCache: 'none',
        }
      )

      if (user) {
        // TODO use later phone number instead of static 'messagingSenderId'
        // let phoneNumber = user.phoneNumber;
        if (!store.state.firebaseApp) {
          const firebaseConfig = {
            apiKey: 'AIzaSyArFhhaSl5ek7sCiipFYFZ4wned0g_vwp0',
            authDomain: 'sazkamobil-test.firebaseapp.com',
            projectId: 'sazkamobil-test',
            storageBucket: 'sazkamobil-test.appspot.com',
            messagingSenderId: '668904320346',
            appId: '1:668904320346:web:cfd8e9af4504945b091763',
          }

          const firebaseApp = initializeApp(firebaseConfig)
          store.commit('setFirebaseApp', firebaseApp)
        }
        const storedFirebaseApp = store.state.firebaseApp

        // eslint-disable-next-line no-console

        const messaging = getMessaging(storedFirebaseApp)
        onMessage(messaging, (payload) => {
          // eslint-disable-next-line no-console
        })
        Notification.requestPermission().then((permission) => {
          // eslint-disable-next-line no-console
          console.log('permission', permission)
          if (permission === 'granted') {
            getToken(messaging, {
              vapidKey:
                'BCbyukXPL6gq6iOa7Zddxf1D73MLsZpX3SE15L5Hcqx-Bh1Iz9g0C6tkBqxG0MEgJAKr-dUIyL8HjtbT4yDBdO4',
            })
              .then(async (currentToken) => {
                if (currentToken) {
                  // eslint-disable-next-line no-console
                  console.log('currentToken', currentToken)

                  const dataObject = {
                    deviceToken: currentToken,
                    deviceOS: navigator.userAgentData.platform.toString(),
                    externalSubscriberId: user?.subscriberId ?? '',
                  }

                  const dataStr = JSON.stringify(dataObject)

                  // Send token to backend
                  const optionsSet = {
                    data: dataStr,
                    method: 'post',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    sendAuth: true,
                  }

                  const response = await app.$apiCall(
                    '/wsc/shared/setDeviceToken',
                    optionsSet
                  )

                  if (response && response.status === 200) {
                    store.commit('setFirebaseTokenSent', true)
                  } else {
                    console.error('Error while sending token to backend')
                  }
                } else {
                  console.log(
                    'No registration token available. Request permission to generate one.'
                  )
                }
              })
              .catch((err) => {
                console.log('An error occurred while retrieving token. ', err)
              })
          }
        })
      }

      swRegistration &&
        swRegistration.addEventListener('updatefound', () => {
          // eslint-disable-next-line no-console
          console.log('SW update found')
          if (swUpdate && swUpdate.installing) {
            window.location.reload()
          }
        })
    }

    window.addEventListener('focus', async () => {
      try {
        swUpdate = swRegistration && (await swRegistration.update())
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('SW update failed', e)
      }
    })
  }
}
