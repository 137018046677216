<template>
  <footer
    :class="
      'o-footer u-bordered-side ' +
      (footerStyle === 'dark' ? 'o-footer--dark' : '')
    "
    :data-component="componentName"
  >
    <b-container>
      <b-row>
        <b-col class="u-call-us" lg="9" xl="9">
          <p class="u-call-us__heading">{{ strapiCallUs.heading }}</p>
          <b-row>
            <b-col
              v-for="item in strapiCallUs.callUsLink"
              :key="item.id"
              md="4"
              lg="4"
            >
              {{ item.heading }}
              <a class="u-call-us__link" :href="item.link.linkUrl">{{
                item.link.title
              }}</a>
            </b-col>
          </b-row>
          <div class="u-call-us__text">{{ strapiCallUs.footNote }}</div>
        </b-col>
      </b-row>

      <FooterMenu :menu-data="FooterMenuData"></FooterMenu>

      <FooterMenuIcons :menu-data="FooterMenuIconsData"></FooterMenuIcons>

      <a href="https://www.vodafone.cz/" target="_blank" class="u-disclamer">
        <img-svg
          class="u-disclamer__logo"
          src="vodafone-icon-rgb-black.svg"
        ></img-svg>
        © 2024 Vodafone Czech Republic a.s.
      </a>
    </b-container>
  </footer>
</template>

<script>
import FooterMenu from '../components/FooterMenu'
import FooterMenuIcons from '../components/FooterMenuIcons'
import ImgSvg from './common/imgSvg'

export default {
  name: 'Footer',
  components: { FooterMenu, FooterMenuIcons, ImgSvg },
  props: {
    styleMode: {
      type: String,
      default: () => {
        return 'light'
      },
    },
  },
  data() {
    return {
      componentName: this.$options.name,
      footerStyle: this.styleMode,
      FooterMenuData: [
        {
          title: 'English',
          href: 'https://www.sazkamobil.cz/en-prepaid-card/',
        },
        {
          title: 'Pro novináře',
          href: 'https://www.sazkamobil.cz/pro-novinare/',
        },
        {
          title: 'Zpracování osobních údajů',
          href: 'https://cdn.sazkamobil.cz/sazkamobil/image/upload/v1711957779/sazkamobil/ke-stazeni/ostatni-dokumenty/20240401_SAZKAmobil_Prohlaseni_o_zpracovani_OU.pdf',
        },
        { title: 'Akce', href: 'https://www.sazkamobil.cz/akce/' },
        { title: 'Podpora', href: 'https://www.sazkamobil.cz/podpora/' },
        {
          title: 'Nastavení cookies',
          href: 'https://www.vodafone.cz/o-vodafonu/ke-stazeni/ochrana-soukromi/cookies/',
        },
        {
          title: 'VOP',
          href: 'https://www.sazkamobil.cz/podpora/dokumenty-ke-stazeni/#odskok-podminky',
        },
        {
          title: 'Ceník',
          href: 'https://www.sazkamobil.cz/podpora/dokumenty-ke-stazeni/#odskok-ceniky',
        },
      ],
      FooterMenuIconsData: [
        {
          title: 'Sledujte nás na Facebook',
          code: 'fcb',
          href: 'https://www.facebook.com/SAZKAmobil',
          target: '_blank',
        },
        {
          title: 'Sledujte nás na Instagramu',
          code: 'ins',
          href: 'https://www.instagram.com/sazka_mobil',
          target: '_blank',
        },
        {
          title: 'Stáhněte si aplikaci pro Android',
          code: 'drd',
          href: 'https://play.google.com/store/apps/details?id=cz.sazka.sazkamobil',
          target: '_blank',
        },
        {
          title: 'Stáhněte si aplikaci pro iOs',
          code: 'ios',
          href: 'https://apps.apple.com/cz/app/sazkamobil/id845171111',
          target: '_blank',
        },
        {
          title: 'Sledujte nás na YouTube',
          code: 'ytb',
          href: 'https://www.youtube.com/playlist?list=PLYgWSC09bLw4eLaZn38kDUB3rqePDpic3',
          target: '_blank',
        },
      ],
    }
  },
  computed: {
    strapiCallUs() {
      return {
        id: this.$config.strapiData.callUs?.data.id,
        ...this.$config.strapiData.callUs?.data?.attributes,
      }
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/styles/o-footer';
@import '~/assets/styles/u-call-us';
@import '~/assets/styles/u-disclamer';
</style>
