// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/arrow-up.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c-collapse.c-collapse--help .c-collapse__title{padding:0 .5rem;margin-bottom:2.5rem;font-weight:600;font-size:1.5rem}.c-collapse.c-collapse--help .c-collapse__acc-item{position:relative;padding:.5rem}.c-collapse.c-collapse--help .c-collapse__acc-item:last-child{margin-bottom:0}.c-collapse.c-collapse--help .c-collapse__acc-item:after,.c-collapse.c-collapse--help .c-collapse__acc-item:first-child:before{content:\"\";position:absolute;bottom:0;left:0;width:100%;height:3px;background:#f1f1ec}.c-collapse.c-collapse--help .c-collapse__acc-item:first-child:before{top:0;bottom:auto}.c-collapse.c-collapse--help .c-collapse__acc-header{margin-bottom:0}.c-collapse.c-collapse--help .c-collapse__acc-opener{position:relative;display:block;padding-right:3rem;margin-bottom:.5rem;font-weight:500;font-size:.8125rem;line-height:1.4;color:#131c4e}.c-collapse.c-collapse--help .c-collapse__acc-opener:hover{text-decoration:none;color:#2452e0}.c-collapse.c-collapse--help .c-collapse__acc-opener:after{content:\"\";position:absolute;top:0;right:0;display:block;width:1.5rem;height:1.5rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50% no-repeat;transform:rotateX(180deg);transition:transform .2s linear;transform-style:preserve-3d}.c-collapse.c-collapse--help .c-collapse__acc-opener.colapsed,.c-collapse.c-collapse--help .c-collapse__acc-opener[aria-expanded=true]{color:#1342d1}.c-collapse.c-collapse--help .c-collapse__acc-opener.colapsed:after,.c-collapse.c-collapse--help .c-collapse__acc-opener[aria-expanded=true]:after{transform:rotateX(0deg)}.c-collapse.c-collapse--help .c-collapse__acc-body{margin-bottom:1.5rem;font-size:.8125rem}.c-collapse.c-collapse--help .c-collapse__acc-content{padding-right:5.5rem}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
