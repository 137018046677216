<template>
  <b-form-input
    v-model="inputVal"
    placeholder="+420 704 705 706"
    autocomplete="billing tel"
    :state="state"
    type="tel"
    pattern="^\+420[\s]?([0-9]{3}[\s]?[0-9]{3}[\s]?[0-9]{3})$"
    @focus="inputFocus"
    @blur="inputBlur"
  ></b-form-input>
</template>

<script>
export default {
  name: 'PhoneInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    state: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    state() {
      if (this.state) {
        this.$emit('inputChange')
      }
    },
  },
  methods: {
    inputFocus(e) {
      // add +420 prefix (on focus when input is empty)
      const input = e.target
      if (this.inputVal === '' && input.setSelectionRange) {
        this.inputVal = '+420'
        input.setSelectionRange(this.inputVal.length, this.inputVal.length)
      }
    },
    inputBlur() {
      // add +420 prefix (on blur)
      let number = this.inputVal.replace(/\s/g, '')

      if (number.length === 9 && /\d{9}/.test(number)) {
        number = '+420' + number
      }

      if (number.length === 13 && number.substring(0, 4) === '+420') {
        this.inputVal = `${number.slice(0, 4)} ${number.slice(
          4,
          7
        )} ${number.slice(7, 10)} ${number.slice(10)}`
      }
      // for validation purposes (vee-validate)
      // from https://logaretm.github.io/vee-validate/guide/state.html#flags
      // To support all the flags for your custom component
      // you need to emit an input event and a blur event.
      // we do not need to emit input since we validate only on blur
      this.$emit('blur')
    },
  },
}
</script>
