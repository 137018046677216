<template>
  <div class="o-main-nav" :data-component="componentName">
    <b-collapse
      id="collapse-nav"
      class="o-main-nav__collapse-nav"
      :visible="false"
    >
      <div
        v-b-toggle.collapse-nav
        class="o-main-nav__window-close"
        @click="removeOverlay"
      ></div>
      <b-container class="o-main-nav__inner">
        <div class="o-main-nav__nav">
          <div v-if="menuItems">
            <a
              v-if="isApp"
              v-b-toggle.collapse-nav
              href="#"
              class="o-main-nav__close"
              @click.prevent="$toggleBodyModalClass"
            >
              <img-svg
                width="30"
                height="30"
                src="o-header__item-link--toggler-active.svg"
              ></img-svg>
            </a>
            <div
              v-for="item in menuItems"
              :key="item.id"
              class="o-main-nav-item"
            >
              <p class="o-main-nav-item__title">
                <nuxt-link
                  v-b-toggle="item.href ? 'collapse-nav' : 'nav-' + item.id"
                  :to="item.href || '#'"
                  :class="['nav-link', $route.path === item.href && 'active']"
                  @click="item.href ? removeOverlay : null"
                >
                  <span
                    :class="[
                      item.koloOdmen
                        ? 'nav-link-green o-main-nav-item__title-text'
                        : 'o-main-nav-item__title-text',
                    ]"
                  >
                    {{ item.name }}
                  </span>
                </nuxt-link>
                <img-svg
                  v-if="item.subItems && item.subItems.length"
                  v-b-toggle="'nav-' + item.id"
                  class="o-main-nav-item__title-arrow"
                  width="30"
                  height="30"
                  src="o-main-nav-item-arrow.svg"
                ></img-svg>
              </p>

              <b-collapse
                :id="'nav-' + item.id"
                class="o-main-nav-item__collapse"
                accordion="main-nav"
                role="tabpanel"
                :visible="item.id === 'account'"
              >
                <ul class="nav">
                  <li
                    v-for="subItem in item.subItems"
                    :key="subItem.name"
                    v-b-toggle.collapse-nav
                    class="nav-item"
                    @click="$gtmEvent('menu klik', 'WSC', subItem.name)"
                  >
                    <nuxt-link
                      v-if="subItem.href"
                      class="nav-link"
                      :to="subItem.href"
                    >
                      {{ subItem.name }}
                    </nuxt-link>
                    <div
                      v-if="isApp && subItem.accType"
                      class="nav-link o-main-nav-item__specialLink"
                      @click="onSubMenuClick(subItem.accType)"
                    >
                      {{ subItem.name }}
                    </div>
                    <b-modal
                      v-model="modalShow"
                      modal-footer="false"
                      size="lg"
                      hide-footer
                      hide-header
                    >
                      <div class="modalHeader">
                        <h3 class="modalHeader__title">
                          {{
                            modalType === 'accSwitch'
                              ? 'Přepnout účet'
                              : 'Správa účtů'
                          }}
                        </h3>
                        <p
                          v-if="
                            userList &&
                            userList.length === 0 &&
                            modalType === 'accSwitch'
                          "
                          class="text-muted font-size-small mb-0 mt-3"
                        >
                          Nemáte přidaný žádný další účet.
                        </p>
                        <span
                          class="modal-header__close"
                          @click="hideSwitchUserModal"
                        >
                          <img-svg
                            width="30"
                            height="30"
                            src="modal-close--md.svg"
                          ></img-svg>
                        </span>
                      </div>
                      <SwitchUsers
                        v-if="userList.length && modalType === 'accSwitch'"
                      />
                      <EditUsers
                        v-else-if="!userList.length || modalType === 'accEdit'"
                        @userListChange="userListChangeHandler"
                      />
                    </b-modal>
                  </li>
                </ul>
              </b-collapse>
            </div>
          </div>
        </div>
        <div v-if="isApp" class="app-version">
          SAM-app.{{ $config.version }}
        </div>
      </b-container>
    </b-collapse>
  </div>
</template>

<script>
import ImgSvg from './common/imgSvg'
import SwitchUsers from './SwitchUsers'
import EditUsers from './EditUsers'
import { getAuthToken, getSavedUsersList, getUser } from '~/config/common'

export default {
  name: 'HeaderMobileNav',
  components: {
    ImgSvg,
    SwitchUsers,
    EditUsers,
  },
  data() {
    return {
      isApp: false,
      componentName: this.$options.name,
      searchQuery: '',
      user: null,
      menuItemsAll: {
        menuItemsPrepaid: [
          {
            id: 'overview',
            name: 'Přehled',
            href: this.$sysPage('overview'),
          },
          {
            name: 'Odměny',
            href: this.$sysPage('rewards'),
          },
          {
            id: 'recharge',
            name: 'Dobíjení',
            href: this.$sysPage('recharge'),
          },
          {
            id: 'reports',
            name: 'Výpisy',
            href: this.$sysPage('reports'),
          },
          {
            id: 'packages',
            name: 'Balíčky',
            href: this.$sysPage('packages'),
          },
          {
            id: 'services',
            name: 'Služby',
            href: this.$sysPage('services'),
          },
          {
            id: 'rating',
            name: 'Hodnocení',
            href: this.$sysPage('rating'),
          },
          {
            id: 'portation',
            name: 'Přenos čísla',
            href: this.$sysPage('numberTransmission'),
          },
          {
            id: 'personal',
            name: 'Osobní',
            href: this.$sysPage('personalData'),
          },
          // {
          //   id: 'rewardsWheel',
          //   name: 'Kolo odměn',
          //   href: this.$sysPage('rewardsWheel'),
          //   koloOdmen: true,
          // },
          {
            id: 'account',
            name: 'Účet',
            subItems: [
              {
                name: 'Odhlásit',
                href: this.$sysPage('logout'),
              },
              {
                name: 'Přepnout účet',
                accType: 'accSwitch',
              },
              {
                name: 'Správa účtů',
                accType: 'accEdit',
              },
            ],
          },
        ],
        menuItemsPostpaid: [
          {
            id: 'overview',
            name: 'Přehled',
            href: this.$sysPage('overview'),
          },
          {
            id: 'pay',
            name: 'Vyúčtování',
            href: this.$sysPage('postpaidPayment'),
          },
          {
            id: 'reports',
            name: 'Výpisy',
            href: this.$sysPage('reports'),
          },
          {
            id: 'packages',
            name: 'Balíčky',
            href: this.$sysPage('packages'),
          },
          {
            id: 'services',
            name: 'Služby',
            href: this.$sysPage('services'),
          },
          {
            id: 'rating',
            name: 'Hodnocení',
            href: this.$sysPage('rating'),
          },
          {
            id: 'portation',
            name: 'Přenos čísla',
            href: this.$sysPage('numberTransmission'),
          },
          {
            id: 'personal',
            name: 'Osobní',
            href: this.$sysPage('personalData'),
          },
          {
            id: 'account',
            name: 'Účet',
            subItems: [
              {
                name: 'Odhlásit',
                href: this.$sysPage('logout'),
              },
              {
                name: 'Přepnout účet',
                accType: 'accSwitch',
              },
              {
                name: 'Správa účtů',
                accType: 'accEdit',
              },
            ],
          },
        ],
      },
      userList: [],
    }
  },
  computed: {
    menuItems() {
      if (this.user && this.user.subscribtionType) {
        return this.user.subscribtionType === 'POSTPAID'
          ? this.menuItemsAll.menuItemsPostpaid
          : this.menuItemsAll.menuItemsPrepaid
      }
      return null
    },
    modalShow: {
      get() {
        return !!this.$store.state.switchUserModalActive
      },
      set(value) {
        this.$store.commit('setSwitchUserModalActive', value)
      },
    },
    modalType() {
      return this.$store.state.switchUserModalActive
    },
  },
  mounted() {
    const user = getUser()
    this.userList = getSavedUsersList(user.accountId) ?? []
    const authToken = getAuthToken()

    if (authToken) {
      this.user = user
    }
    this.removeOverlay()

    if (
      (this.$store.state.webOrApp && this.$store.state.webOrApp === 'app') ||
      (this.$store.state.appPlatform && this.$store.state.appPlatform === 'ios')
    ) {
      this.isApp = true
    }
  },
  methods: {
    userListChangeHandler(userListUpdated) {
      this.userList = userListUpdated || []
    },
    search() {
      this.$store.commit('setContextData', { searchQuery: this.searchQuery })
      this.$router.push(this.$sysPage('26'))
    },
    removeOverlay() {
      document.querySelector('body').classList.remove('modal-open')
    },
    onSubMenuClick(type) {
      this.$store.commit('setSwitchUserModalActive', type)
    },
    hideSwitchUserModal() {
      this.$store.commit('setSwitchUserModalActive', false)
    },
    async getBuyedPhone() {
      const options = {
        method: 'get',
        sendAuth: true,
      }
      const response = await this.$apiCall(
        '/wsc/shared/profile/getBuyedPhone',
        options
      )
      if (response && response.status === 200) {
        return response.data
      }
    },
  },
}
</script>

<style lang="scss">
.o-main-nav {
  position: fixed;
  top: $header-height + 0.5rem;
  right: 0;
  left: 0;
  z-index: 120;

  &__inner {
    position: relative;
    z-index: 1;
    padding: ($spacer * 2) 0;
  }

  &__nav {
    margin-bottom: 45px;
  }

  &__close {
    position: absolute;
    top: 2rem;
    right: 1rem;
    z-index: 1;
    display: block;
  }

  .o-main-nav__collapse-nav {
    // stylelint-disable-next-line  unit-whitelist
    height: calc(100vh - #{$header-height});
    background-color: $gray-100;

    .modal-open & {
      bottom: 0;
      overflow-y: auto;
    }
  }

  &__window-close {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    height: 1000%;
  }
}

.o-main-nav-item {
  position: relative;
  padding-bottom: $spacer * 0.5;

  &__title {
    padding: ($spacer * 0.5) ($spacer * 0.5) ($spacer * 0.5) ($spacer * 3);
    margin: 0;
    font-weight: $font-weight-bolder;
    font-size: 0.8125rem;
    line-height: 1.4;
    text-transform: uppercase;

    .nav-link {
      position: relative;
      padding: 0 ($spacer * 2) 0 0;
      color: $gray-900;

      &:hover {
        color: $blue;
      }
    }
  }

  &__specialLink {
    cursor: pointer;
  }

  .nav-link.active &__title-text {
    font-weight: $font-weight-bolder;
    color: $blue;

    &:hover {
      color: $blue;
    }
  }

  &__title-arrow {
    position: absolute;
    top: map-get($spacers, 1);
    right: map-get($spacers, 3);
    transform: rotate(0);
    transition: 0.2s linear transform;
  }

  &__title-arrow[aria-expanded='true'] {
    transform: rotate(180deg);
  }

  &__collapse {
    .nav {
      display: block;
    }

    .nav-item {
      margin-left: $spacer * 3.5;
      font-weight: $font-weight-bold;
      font-size: 0.8125rem;
      line-height: 1.45;
    }

    // stylelint-disable-next-line
    .nav-link {
      display: inline-block;
      padding: ($spacer * 0.5) ($spacer * 0.5) ($spacer * 0.5) $spacer;
      color: $gray-900;

      &:hover,
      &.active {
        font-weight: $font-weight-bold;
        color: $blue;
      }
    }
  }
}

.modalHeader {
  margin-bottom: 2rem;
  font-size: 1.2rem;

  &__title {
    font-weight: $font-weight-bolder;
  }
}

.app-version {
  padding: 0.25rem;
  font-size: 0.5rem;
  text-align: center;
  color: $gray-500;
}

.nav-link-green {
  color: #46c152 !important;
}
</style>
