<!--splash screen has duplicated code fragment in app.html to prevent showing blank page before load-->
<!--some changes may need to be applied also in app.html-->
<template>
  <div
    :class="['splashScreen', hasInitialInfo ? 'splashScreen--has-info' : null]"
  >
    <img src="~/assets/images/logo_sazkamobil.png" class="splashScreen__logo" />
    <div class="splashScreen__logo-title">Váš mobilní operátor</div>

    <transition name="fade">
      <div
        v-if="hasInitialInfo && logoMoveAnimationComplete"
        class="container splashScreen__initial-info-wrapper"
      >
        <div class="splashScreen__initial-info-main">
          <h2 class="splashScreen__initial-info-title">
            Přehled vašeho volání, SMS a datových služeb.
          </h2>
          <b-button variant="primary" block @click="hideSplashScreen"
            >Vstoupit</b-button
          >
        </div>
        <div class="splashScreen__bottom-fixed-content">
          <h2 class="splashScreen__initial-info-title-sec">
            Pro kontrolu loterií a her<br />přejděte na web
            <a href="https://www.sazka.cz/mobilni-aplikace/sazka">Sazky</a>.
          </h2>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { getPermanentLoggedUser, getUser } from '@/config/common'

const storageInitialInfoKey = 'spsInitialInfo'

export default {
  name: 'SplashScreen',
  data() {
    return {
      hasInitialInfo: false,
      logoMoveAnimationComplete: false,
    }
  },
  mounted() {
    const splashItem = document.getElementById('bgSplashscreen')
    if (splashItem !== null && splashItem !== undefined) {
      splashItem.remove()
    }
    const ssHideTimeout = 1000

    // showing initial data on first app use
    // if user data are set, app has been launched before - not a first use scenario
    if (
      this.$webOrApp !== 'app' ||
      getPermanentLoggedUser() ||
      getUser() ||
      window.localStorage.getItem(storageInitialInfoKey)
    ) {
      this.setStorageInitialInfo()
      window.setTimeout(() => {
        this.hideSplashScreen()
      }, ssHideTimeout)
    } else {
      this.setStorageInitialInfo()
      // first use scenario
      window.setTimeout(() => {
        this.hasInitialInfo = true
        window.setTimeout(() => {
          this.logoMoveAnimationComplete = true

          // timeout value for displaying splash info data
          // should be higher than logo transition animation time (which is 400ms)
        }, 500)
      }, ssHideTimeout)
    }
  },
  methods: {
    setStorageInitialInfo() {
      window.localStorage.setItem(storageInitialInfoKey, '1')
    },
    hideSplashScreen() {
      this.$store.commit('setSplashScreenIsHidden', true)
    },
  },
}
</script>

<style lang="scss">
// splash screen has duplicated code fragment in app.html to prevent showing blank page before load
// some changes may need to be applied also in app.html
.splashScreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  background-color: $yellow;

  &__logo {
    margin-top: 20vh;
    display: block;
    width: 50%;
    max-width: 320px;
    transition: margin-top 0.4s;

    .splashScreen--has-info & {
      margin-top: $spacer * 2;
    }
  }

  &__initial-info-wrapper {
    padding-bottom: 6.75rem;
  }

  &__initial-info-main {
    padding: $spacer * 2;
    background-color: $white;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  &__bottom-fixed-content {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 3rem;
  }

  &__logo-title {
    font-weight: 900;
    margin-top: 4vh;
    font-size: 1rem;

    @include media-breakpoint-up(md) {
      font-size: 1.2rem;
    }
  }

  &__initial-info-title {
    font-size: 1.25rem;
    margin-bottom: $spacer * 2;
  }

  &__initial-info-title-sec {
    font-size: 1.25rem;
    margin-bottom: $spacer;
  }

  &__initial-info-subtitle,
  &__initial-info-text {
    font-size: 0.8125rem;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
