import Vue from 'vue'
import VueRouter from 'vue-router'
import { parseJwt } from '~/mixins/parseJwt'
import {
  clearPermanentLoggedUser,
  getAuthToken,
  getPermanentLoggedUser,
  getUser,
  loginCall,
  setUser,
  nonAuthPathKeys,
  trackingPush,
  getGtmUserConsentLevel,
  getGtmLeadId,
} from '~/config/common'

Vue.use(VueRouter)

// Wrapper around original router to handle NavigationDuplicated error
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return new Promise((resolve, reject) => {
    originalPush.call(
      this,
      location,
      () => {
        // on complete
        resolve(this.currentRoute)
      },
      (error) => {
        // only ignore NavigationDuplicated error
        if (error.name === 'NavigationDuplicated') {
          resolve(this.currentRoute)
        } else {
          reject(error)
        }
      }
    )
  })
}

export default ({ app, store }) => {
  app.router.beforeEach(async (to, _from, next) => {
    let authToken = getAuthToken()
    let tokenParsed = false

    // check for permanent login credentials if not logged in within session
    if (!authToken) {
      const permanentLoggedUser = getPermanentLoggedUser()
      if (permanentLoggedUser) {
        await loginCall(
          app,
          permanentLoggedUser.username,
          permanentLoggedUser.password
        )
        authToken = getAuthToken()

        // pokud se nepovede prihlasit, vymazeme pernamentni prihlaseni
        if (!authToken) {
          clearPermanentLoggedUser()
          // eslint-disable-next-line no-console
          console.info('Error Permanent login :' + permanentLoggedUser.username)
        }
      }
    } else {
      tokenParsed = authToken ? parseJwt(authToken) : false
      if (tokenParsed) {
        setUser(tokenParsed)
      }
    }

    const nonAuthPaths = nonAuthPathKeys.map((item) => app.$sysPage(item))
    const publicPaths = [app.$sysPage('confirmEmail')]
    const prepaidBlockedPaths = []
    const postpaidBlockedPaths = [
      /* app.$sysPage('rewardsWheel') */
    ]
    const { subscribtionType } = tokenParsed

    if (
      publicPaths.includes(to.path) ||
      (!authToken && to && nonAuthPaths.includes(to.path))
    ) {
      next()
    } else if (!authToken && to && !nonAuthPaths.includes(to.path)) {
      // pokud se uzivatel snazi dostat na stranku, na kterou je treba byt prihlaseny, ulozime
      if (to.fullPath !== '') {
        window.sessionStorage.setItem('nextStepAfterLogin', to.fullPath)
      }
      await app.router.replace(app.$sysPage('login'))
      next()
      return null
    } else if (authToken && to && nonAuthPaths.includes(to.path)) {
      await app.router.replace(app.$sysPage('overview'))
      next()
      return null
    } else if (
      subscribtionType &&
      subscribtionType === 'PREPAID' &&
      to &&
      prepaidBlockedPaths.includes(to.path)
    ) {
      await app.router.replace(app.$sysPage('overview'))
      next()
      return null
    } else if (
      subscribtionType &&
      subscribtionType === 'POSTPAID' &&
      to &&
      postpaidBlockedPaths.includes(to.path)
    ) {
      await app.router.replace(app.$sysPage('overview'))
      next()
      return null
    } else if (to && to.path === '/') {
      await app.router.replace(app.$sysPage('overview'))
      next()
      return null
    } else {
      next()
    }
  })

  app.router.afterEach((to) => {
    const user = getUser()

    trackingPush({
      event: 'page_view',
      user_data: {
        user_id: user?.customerId || undefined,
        lead_id: getGtmLeadId(),
        device_id: undefined,
        device_advertising_id: undefined,
        user_segment: undefined,
        user_ltv: undefined,
        user_type: user?.subscribtionType?.toLowerCase() || undefined,
        subscriber_id: user?.subscriberId || undefined,
        credit_remaining: undefined,
        selected_plan: undefined,
        minutes_remaining: undefined,
        data_remaining: undefined,
        selected_reward: undefined,
        active_package: undefined,
        profile_services: {
          mms: undefined,
          premium_sms: undefined,
          audiotex: undefined,
          foreign_calls: undefined,
          foreign_calls_outgoing: undefined,
          missed_calls: undefined,
          pricing_info: undefined,
          child_protection: undefined,
          internetwork_call_info: undefined,
        },
        nps: undefined,
        simkacky_amount: undefined,
        simkacky_challenge: undefined,
        wheel_spins_lifetime: undefined,
        user_consent_level: getGtmUserConsentLevel(),
      },
      environment: process.env.NUXT_ENV_GTM_ENV,
      locale:
        app.$webOrApp === 'web'
          ? 'wsc~web'
          : store.state.appPlatform && store.state.appPlatform === 'ios'
          ? 'msc~ios'
          : 'msc~android',
      page_type: undefined,
      page_location: window.location.href,
      page_language: 'cz',
    })
  })
}
