export default {
  state() {
    return {
      contextData: null,
      webOrApp: 'web',
      appPlatform: null,
      splashScreenIsHidden: false,
      switchUserModalActive: false,
      consents: {},
      simkackaCount: -1,
      firebaseApp: null,
    }
  },
  mutations: {
    setContextData(state, contextData) {
      state.contextData = contextData
    },
    setWebOrApp(state, webOrApp) {
      state.webOrApp = webOrApp
    },
    setAppPlatform(state, appPlatform) {
      state.appPlatform = appPlatform
    },
    setSplashScreenIsHidden(state, splashScreenIsHidden) {
      state.splashScreenIsHidden = splashScreenIsHidden
    },
    setSwitchUserModalActive(state, value) {
      state.switchUserModalActive = value
    },
    setConsents(state, value) {
      state.consents = value
    },
    setSimkackaCount(state, value) {
      state.simkackaCount = value
    },
    setFirebaseApp(state, value) {
      state.firebaseApp = value
    },
  },
}
