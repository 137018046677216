import Vue from 'vue'
import { DateTime } from 'luxon'

const filters = {
  /**
   * Converts string to upper case
   * @param {string} value - The string to be converted.
   * @return {string} The uppercase string.
   */
  uppercase(value) {
    if (typeof value === 'string' || value instanceof String) {
      return value.toUpperCase()
    } else {
      /* eslint-disable-next-line no-console */
      console.log(
        `WARNING: Uppercase filter is getting a ${typeof value} type instead of a string type`,
        value
      )
      return value
    }
  },

  /**
   * Returns file size in customized format
   * @param {number|string} filesize - The filesize in bites.
   * @param {number} decimals.
   * @return {string} The filesize with size unit string.
   */
  prettyBytes(filesize, decimals = 2) {
    if (isNaN(filesize) || !isFinite(filesize)) {
      /* eslint-disable-next-line no-console */
      console.log(
        `WARNING: prettyBytes filter is getting a ${typeof filesize} type instead of a number type:`,
        filesize
      )
      return
    }

    // value in KB
    filesize = filesize / 8000
    if (filesize < 1) {
      return '1 KB'
    }
    let i = Math.floor(Math.log(filesize) / Math.log(1000))
    if (i < 0) {
      i = 0
    }
    const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB', 'BB']

    if (i >= units.length) {
      /* eslint-disable-next-line no-console */
      console.log(
        `WARNING: prettyBytes filter is getting a value outside of units indices`,
        filesize
      )
      return
    }

    return (
      parseFloat(
        (filesize / Math.pow(1000, i)).toFixed(decimals)
      ).toLocaleString('cs-cz') +
      ' ' +
      units[i]
    )
  },

  /**
   * Returns number in customized format (can be used for currency etc.)
   * @param {integer|float} numberToFormat - Number you want to format
   * @param {object[]} optionals - Parameters as accepted in Number.toLocaleString()
   */
  formatCurrencyToLocale(
    numberToFormat,
    optionals = {
      locales: ['cs-cz'],
      options: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    }
  ) {
    if (numberToFormat !== 0 && (!numberToFormat || numberToFormat === '')) {
      // eslint-disable-next-line no-console
      console.log(
        `WARNING: FormatCurrencyToLocale filter is getting an invalid value`,
        numberToFormat
      )
      return numberToFormat
    }

    const { locales, options } = optionals

    return parseFloat(numberToFormat).toLocaleString(locales, options)
  },

  /**
   * Returns number in format Integer number with currency
   */
  formatCurrencyToLocaleInteger(numberToFormat) {
    const locales = ['cs-cz']
    const options = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }

    return parseFloat(numberToFormat).toLocaleString(locales, options)
  },

  /**
   * Returns date in customized format (can be used for currency etc.)
   * @param {string} dateISO - Date you want to format in ISO code
   * @param {object[]} locales - Parameters as accepted in String.toLocaleString()
   * You can find more locales examples in LUXON library documentation for more customized date formating
   */
  formatDateToLocale(dateISO) {
    if (dateISO === undefined || dateISO === null || dateISO === '') {
      return dateISO
    }

    return DateTime.fromISO(dateISO).toFormat('d. L. yyyy')
  },

  /**
   * Returns format day with description
   * @param {int} countOfDay - day
   */
  daysSuffixInflected(countOfDayTxt) {
    const countOfDay = parseInt(countOfDayTxt)
    if (isNaN(countOfDay)) {
      return ''
    }

    if (countOfDay === -1) {
      return 'Probíhá vyhodnocování'
    } else if (countOfDay === 0) {
      return 'Dnes'
    } else if (countOfDay === 1) {
      return `Zítra`
    } else if (countOfDay > 4) {
      return `Za ${countOfDay} dnů`
    } else {
      return `Za ${countOfDay} dny`
    }
  },

  /**
   * Returns format tickets with description
   * @param {int} countOfLotteryTicket - day
   */
  ticketsSuffixInflected(countOfLotteryTicket) {
    if (
      !countOfLotteryTicket ||
      countOfLotteryTicket === 0 ||
      countOfLotteryTicket > 4
    ) {
      return countOfLotteryTicket + ' sázek'
    } else if (countOfLotteryTicket === 1) {
      return countOfLotteryTicket + ' sázku'
    } else {
      return countOfLotteryTicket + ' sázky'
    }
  },

  /**
   * Format phone number
   * @param {string|number} msisdnNumber - non prefixed non formatted phone number
   * @returns {string} formatted phone number
   */
  phoneFromMsisdn(msisdnNumber) {
    if (!msisdnNumber) {
      return ''
    }
    let formattedNumber = msisdnNumber
    if (
      typeof formattedNumber === 'number' ||
      formattedNumber instanceof Number
    ) {
      formattedNumber = msisdnNumber.toString()
    }
    formattedNumber = formattedNumber.replace(/\s/g, '')
    formattedNumber =
      formattedNumber.length === 9
        ? (formattedNumber = `420${formattedNumber}`)
        : formattedNumber

    if (
      (typeof formattedNumber === 'string' ||
        formattedNumber instanceof String) &&
      formattedNumber.length === 12
    ) {
      return `+${formattedNumber.slice(0, 3)} ${formattedNumber.slice(
        3,
        6
      )} ${formattedNumber.slice(6, 9)} ${formattedNumber.slice(9)}`
    } else {
      /* eslint-disable-next-line no-console */
      console.log(
        `WARNING: phoneFromMsisdn filter is getting an invalid input value`,
        msisdnNumber
      )
      return msisdnNumber
    }
  },

  /**
   * Returns date in custom format
   * @param {string} isoDate - Date you want to format in ISO code
   * @param {string} format - output date format
   */
  dateTimeCustomFromIso(isoDate, format = 'd. M. HH:mm') {
    const dateFormatted = DateTime.fromISO(isoDate).toFormat(format)
    return dateFormatted || isoDate
  },

  /**
   * Returns number of unit by type
   * @param {int} unit - number of unit
   */
  numberOfUnits(unit, metricUnitCode = '') {
    // pieces
    if (metricUnitCode === 'ITEM') {
      return unit + ' ks'
    }

    // byte
    if (metricUnitCode === 'KB') {
      return unit + ' kB'
    }

    // misc as minute - metricUnitCode == 'SEC'
    const min = Math.floor(unit / 60)
    const sec = unit % 60
    return `${min}:${sec < 10 ? '0' : ''}${sec}`
  },

  /**
   * Returns user number in format 987 654 321
   */
  userNumber(input) {
    const inputFormatted = input.replace('420', '')
    return `${inputFormatted.slice(0, 3)} ${inputFormatted.slice(
      3,
      6
    )} ${inputFormatted.slice(6, 9)}`
  },

  /**
   * Returns report info, only replace text for Rewards Wheel
   */
  convertReportInfo(input, text) {
    if (input === 'WIN_ON_REWARDS_WHEEL') {
      return text
    }
    return input
  },
}
for (const property in filters) {
  Vue.filter(property, filters[property])
}
