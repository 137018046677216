<template>
  <div class="u-faq-mfq overflow-x-hidden" :data-component="componentName">
    <div class="c-collapse c-collapse--help">
      <h2 class="h1 w-75 mb-5" v-html="collapseHelpTitle"></h2>
      <div v-if="helpData" class="c-collapse__acc" role="tablist">
        <div
          v-for="(item, index) in helpData"
          :id="index + '-' + 'Help'"
          :key="index"
          class="c-collapse__acc-item"
        >
          <h3 class="c-collapse__acc-header" role="tab">
            <a
              v-b-toggle="index + '-' + 'Help'"
              class="c-collapse__acc-opener"
              @click.prevent=""
              v-html="item.title"
            ></a>
          </h3>
          <b-collapse
            :id="index + '-' + 'Help'"
            v-model="collapseState[index]"
            class="c-collapse__acc-body"
            role="tabpanel"
          >
            <vue-markdown
              class="c-collapse__acc-content"
              :source="item.description"
            ></vue-markdown>
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollapseHelp',
  props: {
    collapseHelpTitle: {
      type: String,
      default: null,
    },
    helpData: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      componentName: this.$options.name,
      collapseState: {},
    }
  },
  computed: {
    rewardsWheel() {
      return this.$config.strapiData.rewardsWheel?.data?.attributes
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/styles/u-help';
</style>
