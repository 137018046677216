<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <b-row class="d-block mt-3">
        <b-col class="mx-auto" md="8" lg="5" xl="6">
          <form-validation v-slot="{ state, error }" rules="required|phone">
            <b-form-group
              id="login-tel-group"
              label="Telefonní číslo"
              label-for="login-tel"
              type="tel"
              :state="state"
              :invalid-feedback="error"
            >
              <PhoneInput
                v-model="formData.username"
                :state="state"
                class="form-control--white-bg"
              ></PhoneInput>
            </b-form-group>
          </form-validation>
          <form-validation v-slot="{ state, error }" rules="required">
            <b-form-group
              id="login-password-group"
              label="Heslo"
              label-for="login-password"
              :invalid-feedback="error"
            >
              <b-form-input
                id="login-password"
                v-model="formData.password"
                type="password"
                placeholder="Zadejte heslo"
                :state="state"
                class="form-control--white-bg"
              ></b-form-input>
            </b-form-group>
          </form-validation>
          <form-validation v-slot="{ state, error }" :rules="aliasRules">
            <b-form-group
              id="idAliasName"
              label="Jméno"
              label-for="aliasName"
              :invalid-feedback="error"
            >
              <b-form-input
                id="aliasName"
                v-model="formData.nickname"
                placeholder="Přezdívka"
                :state="state"
                class="form-control--white-bg"
              ></b-form-input>
            </b-form-group>
          </form-validation>
        </b-col>
        <b-col class="mx-auto" md="6" lg="4" xl="5">
          <b-alert :show="helpers.credentialsInvalid" variant="warning">
            Zadali jste nesprávné přihlašovací údaje
          </b-alert>
          <b-button
            v-if="!helpers.rqLoading"
            v-b-modal.modal-save-user
            type="submit"
            block
            variant="primary"
            @click.prevent
          >
            Uložit
          </b-button>
          <b-button
            v-if="!helpers.rqLoading"
            block
            variant="outline-primary"
            @click="onCancelForm"
          >
            Zrušit
          </b-button>
          <SubmitLoader
            v-if="helpers.rqLoading"
            text="Ověřuji&hellip;"
          ></SubmitLoader>

          <b-modal
            id="modal-save-user"
            hide-footer
            hide-header
            modal-footer="false"
            size="lg"
            title="title"
          >
            <div class="modalHeader">
              <span class="modalHeader__title font-weight-bold">
                Uložit uživatele s heslem?
              </span>
              <span class="modal-header__close">
                <img-svg
                  height="30"
                  src="modal-close--md.svg"
                  width="30"
                ></img-svg>
              </span>
            </div>
            <div class="container text-left">
              <p>
                Varování. Využíváte-li funkci trvalé uložení uživatele s heslem,
                vystavujete se při případné ztrátě nebo kráděži telefonu riziku
                zneužití dat třetí osobou. V těchto případech doporučujeme co
                nejdříve změnit heslo pro přihlášení tak, aby nemohlo dojít ke
                zneužití vašich osobních údajů.
              </p>
              <b-button
                type="submit"
                variant="primary"
                class="w-100"
                @click="
                  $bvModal.hide('modal-save-user')
                  onSubmit()
                "
                >Ano</b-button
              >
              <b-button
                variant="outline-primary"
                class="mt-3 w-100"
                @click="$bvModal.hide('modal-save-user')"
                >Ne</b-button
              >
            </div>
          </b-modal>
        </b-col>
      </b-row>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import ImgSvg from '../components/common/imgSvg'
import FormValidation from '~/components/common/formValidation'
import getJsonData from '~/mixins/getJsonData'
import PhoneInput from '~/components/common/phoneInput'
import SubmitLoader from '~/components/common/submitLoader'
import { loginCall } from '~/config/common'

export default {
  name: 'LoginFormOnly',
  components: {
    FormValidation,
    PhoneInput,
    SubmitLoader,
    ValidationObserver,
    ImgSvg,
  },
  mixins: [getJsonData],
  props: {
    usersList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      formData: {
        nickname: '',
        username: '',
        password: '',
      },
      helpers: {
        rqLoading: false,
        credentialsInvalid: false,
      },
      isWeb: false,
    }
  },
  head: {
    title: 'Přihlášení',
    bodyAttrs: {
      'data-layout': 'default',
      'data-template': 'login',
    },
  },
  computed: {
    existingAliases() {
      return this.usersList.map((item) => item.nickname)
    },
    aliasRules() {
      return {
        required: true,
        notIn: {
          list: this.existingAliases,
        },
      }
    },
  },
  mounted() {
    if (
      this.$store.state.webOrApp &&
      this.$store.state.webOrApp === 'web' &&
      !(
        this.$store.state.appPlatform && this.$store.state.appPlatform === 'ios'
      )
    ) {
      this.isWeb = true
    }
  },
  methods: {
    onCancelForm() {
      this.$emit('collapseCreateUserForm')
    },
    async onSubmit() {
      this.helpers.credentialsInvalid = false
      this.helpers.rqLoading = true

      const response = await loginCall(
        this,
        this.formData.username,
        this.formData.password
      )
      this.helpers.rqLoading = false

      if (response && response.status === 200) {
        this.$emit('userAddedToList', { ...this.formData })
      } else if (
        response &&
        (response.status === 401 || response.status === 400)
      ) {
        this.helpers.credentialsInvalid = true
      }
    },
  },
}
</script>

<style lang="scss">
.o-login {
  padding: ($spacer * 3) $spacer ($spacer * 4);
}
</style>
