import Vue from 'vue'
import Cloudinary, { CldImage, CldTransformation } from 'cloudinary-vue'
import VueMarkdown from '@adapttive/vue-markdown'
import VueResponsive from 'vue-responsive'
import VueGtm from '@gtm-support/vue2-gtm'
import axios from 'axios'
import layout from './../components/Layout'
import {
  sysPagesMap,
  rqStatusEnum,
  setServerConsents,
  getAuthToken,
  setAuthToken,
  getPermanentLoggedUser,
  loginCall,
  addIncentiveForRating,
  clearAuthToken,
  clearUser,
  nonAuthPathKeys,
} from '~/config/common'

export default ({ app, route, store }, inject) => {
  /**
   * Extends Vue with method to get system page link for specific sysPageKey
   * @param {string} sysPageKey - key for desired system page link item
   * @return {string|null} System page link
   */
  const sysPage = function (sysPageKey) {
    if (!sysPagesMap[sysPageKey]) {
      /* eslint-disable-next-line no-console */
      console.log(`Sys Pages Error: The '${sysPageKey}' key does not exist`)
      return null
    }
    return sysPagesMap[sysPageKey]
  }

  // permanentLoginCallActive flag used to prevent duplicated login requests
  let permanentLoginCallActive = false

  const apiCall = async function (url, options = {}) {
    let rqOptions = {
      timeout: 20000,
      ...options,
    }

    const authToken = getAuthToken()

    // authorization (if opt to send)
    if (options.sendAuth && authToken) {
      rqOptions = {
        ...options,
        headers: {
          ...options.headers,
          authorization: authToken,
        },
      }
    }

    const sanitizedUrl = `${process.env.NUXT_ENV_CAPI_URL}${url}`
      .replace(/\/\//g, '/')
      .replace('http:/', 'http://')
      .replace('https:/', 'https://')

    try {
      const response = await axios(sanitizedUrl, rqOptions)
      // checking for offline page response
      // (offline page can be served only on pwa via service worker)
      if (
        response &&
        response.data &&
        typeof response.data === 'string' &&
        response.data.slice(0, 22).includes('offline_page')
      ) {
        // async requests do not display as a page,
        // therefore forcing reload to display offline page on current url
        window.location.reload()
        return
      }

      // storing auth token if present
      if (response && response.headers && response.headers.authorization) {
        setAuthToken(response.headers.authorization)
      }
      return response
    } catch (error) {
      if (axios.isCancel(error)) {
        return
      }

      // unauthorized
      if (error.response?.status === 401) {
        // check for permanent login and use it instead
        const permanentLoggedInUser = getPermanentLoggedUser()
        if (permanentLoggedInUser && !permanentLoginCallActive) {
          permanentLoginCallActive = true

          const response = await loginCall(
            app,
            permanentLoggedInUser.username,
            permanentLoggedInUser.password
          )
          permanentLoginCallActive = false

          if (response && response.status === 200) {
            setAuthToken(response.headers.authorization)

            addIncentiveForRating(this)

            window.location.reload()
            return
          }
        }

        clearAuthToken()
        clearUser()

        // go to login page if not authorized on a page requiring auth
        if (
          !nonAuthPathKeys
            .map((item) => app.$sysPage(item))
            .includes(route.path)
        ) {
          window.location.href = app.$sysPage('login')
        }
      } else if (error.response?.status === 400) {
        // eslint-disable-next-line no-console
        console.log('Request error', error)
      } else if (options.ignoreToast) {
        console.log('Request error', error)
      } else {
        if (!window.apiErrorToast) {
          window.apiErrorToast = true
          if (this.$bvToast && this.$bvToast.toast) {
            this.$bvToast.toast(
              'Akce se nezdařila. Zkuste to prosím později.',
              {
                autoHideDelay: 4000,
                appendToast: true,
              }
            )
          } else {
            alert('Akce se nezdařila. Zkuste to prosím později.')
          }
          window.setTimeout(() => {
            window.apiErrorToast = false
          }, 4000)
        }
        // eslint-disable-next-line no-console
        console.log('Request error', error)
      }
      return error.response
    }
  }

  /**
   *
   * @param {string} rqStatusKey - one of RQ status enum
   * @returns {null|string} - request status verbose
   */
  const rqStatus = function (rqStatusKey) {
    if (!rqStatusEnum[rqStatusKey]) {
      /* eslint-disable-next-line no-console */
      console.log(
        `Request Status Error: The '${rqStatusKey}' key does not exist`
      )
      return null
    }
    return rqStatusEnum[rqStatusKey]
  }

  /**
   * creates GTM event
   * @param {string} category
   * @param {string} action
   * @param {string} label
   */
  const gtmEvent = function (category, action, label, value = '') {
    if (process.isClient) {
      window.dataLayer.push({
        event: 'GAevent',
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value,
      })
    }
  }

  /**
   * toggles open/closed bootstrap class on HTML body element
   */
  const toggleBodyModalClass = function () {
    const bodyClassList = document.querySelector('body').classList
    const helperClass = 'modal-open'
    if (bodyClassList.contains(helperClass)) {
      bodyClassList.remove(helperClass)
      return
    }

    bodyClassList.add(helperClass)
  }

  const cloudinaryImage = function (imagesArray = []) {
    if (imagesArray !== null && imagesArray.length > 0) {
      const image = imagesArray[0]

      // eslint-disable-next-line
      const { public_id, format, version } = image

      // eslint-disable-next-line
      if (public_id === null) return null

      let finalImage = {
        version,
        public_id,
        url:
          process.env.NUXT_ENV_CLOUDINARY_DOMAIN +
          'c_scale,q_auto:eco/' +
          // eslint-disable-next-line
          public_id +
          '.' +
          format,
        caption: '',
        alt: '',
      }

      if ('context' in image) {
        const { context } = image

        if (context === null) return finalImage

        if ('caption' in context) {
          const { caption } = context

          if (caption !== null) {
            finalImage = {
              ...finalImage,
              caption,
            }
          }
        }

        if ('alt' in context) {
          const { alt } = context

          if (alt !== null) {
            finalImage = {
              ...finalImage,
              alt,
            }
          }
        }
      }

      return finalImage
    } else {
      return null
    }
  }

  const strapiLinkResolver = function (linkObject) {
    if (linkObject) {
      const {
        title,
        usePageAsLink,
        useSpecialOfferAsLink,
        useUrlAsLink,
        linkToPage,
        linkToSpecialOffer,
        linkUrl,
      } = linkObject

      const truthyTypes = [
        usePageAsLink,
        useSpecialOfferAsLink,
        useUrlAsLink,
      ].filter((item) => item === false)

      if (truthyTypes.length > 1) {
        process.env.NODE_ENV === 'development' &&
          // eslint-disable-next-line no-console
          console.warn(`Link ${title} has specified multiple links`)
      }

      if (usePageAsLink === true && linkToPage) {
        // const { id, __typename } = linkToPage;
        const { id } = linkToPage

        // Due to gridsome source strapi that we need due to pagination issues we are mixing 2 graphql sources that
        // provide different __typename (gridsome got it wrong) so we must specify string to match instead of __typename

        // return this.$sysPage(`${id}-${__typename}`);
        return this.$sysPage(`${id}-Page`) || ''
      }

      if (useSpecialOfferAsLink === true && linkToSpecialOffer) {
        // const { id, __typename } = linkToSpecialOffer;
        const { id } = linkToSpecialOffer

        // Due to gridsome source strapi that we need due to pagination issues we are mixing 2 graphql sources that
        // provide different __typename (gridsome got it wrong) so we must specify string to match instead of __typename

        // return this.$sysPage(`${id}-${__typename}`);
        return this.$sysPage(`${id}-SpecialOffer`) || ''
      }

      if (useUrlAsLink && linkUrl) {
        return linkUrl
      }
    }
    return null
  }

  // updates user consents in store and on BE
  const updateUserConsents = async (consentData) => {
    const storeConsentData = store.consents
    store.commit('setConsents', {
      ...storeConsentData,
      ...consentData,
    })

    await setServerConsents(app, consentData)
  }

  inject('sysPage', sysPage)
  inject('apiCall', apiCall)
  inject('rqStatus', rqStatus)
  inject('gtmEvent', gtmEvent)
  inject('toggleBodyModalClass', toggleBodyModalClass)
  inject('cloudinaryImage', cloudinaryImage)
  inject('strapiLinkResolver', strapiLinkResolver)
  inject('updateUserConsents', updateUserConsents)

  Vue.component('Layout', layout)
  Vue.component('VueMarkdown', VueMarkdown)
  Vue.use(VueResponsive)
  Vue.use(Cloudinary, {
    configuration: {
      cloudName: process.env.NUXT_ENV_CLOUDINARY_CLOUDNAME,
      secure: true,
    },
    components: [CldImage, CldTransformation],
  })

  if (process.env.NODE_ENV === 'production') {
    Vue.use(VueGtm, {
      id: ['GTM-5HPJZRM'],
      enabled: true,
      debug: false,
    })
  } else {
    window.dataLayer = window.dataLayer || []
  }
}
