var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"o-main-nav",attrs:{"data-component":_vm.componentName}},[_c('b-collapse',{staticClass:"o-main-nav__collapse-nav",attrs:{"id":"collapse-nav","visible":false}},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-nav",modifiers:{"collapse-nav":true}}],staticClass:"o-main-nav__window-close",on:{"click":_vm.removeOverlay}}),_vm._v(" "),_c('b-container',{staticClass:"o-main-nav__inner"},[_c('div',{staticClass:"o-main-nav__nav"},[(_vm.menuItems)?_c('div',[(_vm.isApp)?_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-nav",modifiers:{"collapse-nav":true}}],staticClass:"o-main-nav__close",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$toggleBodyModalClass.apply(null, arguments)}}},[_c('img-svg',{attrs:{"width":"30","height":"30","src":"o-header__item-link--toggler-active.svg"}})],1):_vm._e(),_vm._v(" "),_vm._l((_vm.menuItems),function(item){return _c('div',{key:item.id,staticClass:"o-main-nav-item"},[_c('p',{staticClass:"o-main-nav-item__title"},[_c('nuxt-link',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(item.href ? 'collapse-nav' : 'nav-' + item.id),expression:"item.href ? 'collapse-nav' : 'nav-' + item.id"}],class:['nav-link', _vm.$route.path === item.href && 'active'],attrs:{"to":item.href || '#'},on:{"click":function($event){item.href ? _vm.removeOverlay : null}}},[_c('span',{class:[
                    item.koloOdmen
                      ? 'nav-link-green o-main-nav-item__title-text'
                      : 'o-main-nav-item__title-text' ]},[_vm._v("\n                  "+_vm._s(item.name)+"\n                ")])]),_vm._v(" "),(item.subItems && item.subItems.length)?_c('img-svg',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('nav-' + item.id),expression:"'nav-' + item.id"}],staticClass:"o-main-nav-item__title-arrow",attrs:{"width":"30","height":"30","src":"o-main-nav-item-arrow.svg"}}):_vm._e()],1),_vm._v(" "),_c('b-collapse',{staticClass:"o-main-nav-item__collapse",attrs:{"id":'nav-' + item.id,"accordion":"main-nav","role":"tabpanel","visible":item.id === 'account'}},[_c('ul',{staticClass:"nav"},_vm._l((item.subItems),function(subItem){return _c('li',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-nav",modifiers:{"collapse-nav":true}}],key:subItem.name,staticClass:"nav-item",on:{"click":function($event){return _vm.$gtmEvent('menu klik', 'WSC', subItem.name)}}},[(subItem.href)?_c('nuxt-link',{staticClass:"nav-link",attrs:{"to":subItem.href}},[_vm._v("\n                    "+_vm._s(subItem.name)+"\n                  ")]):_vm._e(),_vm._v(" "),(_vm.isApp && subItem.accType)?_c('div',{staticClass:"nav-link o-main-nav-item__specialLink",on:{"click":function($event){return _vm.onSubMenuClick(subItem.accType)}}},[_vm._v("\n                    "+_vm._s(subItem.name)+"\n                  ")]):_vm._e(),_vm._v(" "),_c('b-modal',{attrs:{"modal-footer":"false","size":"lg","hide-footer":"","hide-header":""},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('div',{staticClass:"modalHeader"},[_c('h3',{staticClass:"modalHeader__title"},[_vm._v("\n                        "+_vm._s(_vm.modalType === 'accSwitch'
                            ? 'Přepnout účet'
                            : 'Správa účtů')+"\n                      ")]),_vm._v(" "),(
                          _vm.userList &&
                          _vm.userList.length === 0 &&
                          _vm.modalType === 'accSwitch'
                        )?_c('p',{staticClass:"text-muted font-size-small mb-0 mt-3"},[_vm._v("\n                        Nemáte přidaný žádný další účet.\n                      ")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"modal-header__close",on:{"click":_vm.hideSwitchUserModal}},[_c('img-svg',{attrs:{"width":"30","height":"30","src":"modal-close--md.svg"}})],1)]),_vm._v(" "),(_vm.userList.length && _vm.modalType === 'accSwitch')?_c('SwitchUsers'):(!_vm.userList.length || _vm.modalType === 'accEdit')?_c('EditUsers',{on:{"userListChange":_vm.userListChangeHandler}}):_vm._e()],1)],1)}),0)])],1)})],2):_vm._e()]),_vm._v(" "),(_vm.isApp)?_c('div',{staticClass:"app-version"},[_vm._v("\n        SAM-app."+_vm._s(_vm.$config.version)+"\n      ")]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }