<template>
  <div :data-name="$options.name">
    <ul v-if="usersList && usersList.length" class="switchUser__list">
      <li
        v-for="user in usersList"
        :key="user.nickname"
        class="switchUser__listItem"
        :class="{ active: isUserLoggedIn(user) }"
      >
        <a href="#" @click.prevent="switchUser(user.username, user.password)">
          {{ user.nickname }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  getAuthToken,
  getSavedUsersList,
  setUser,
  getUser,
  formatPhoneNumberToId,
  loginCall,
  clearPermanentLoggedUser,
  addIncentiveForRating,
} from '~/config/common'
import getJsonData from '~/mixins/getJsonData'
import parseJwt from '~/mixins/parseJwt'

export default {
  name: 'SwitchUsers',
  mixins: [getJsonData, parseJwt],
  data() {
    return {
      activeUser: null,
      usersList: [],
      helpers: {
        rqLoading: false,
        credentialsInvalid: false,
      },
    }
  },
  mounted() {
    const currentUser = getUser()
    this.usersList = getSavedUsersList(currentUser.accountId)
  },
  methods: {
    isUserLoggedIn(user) {
      return getUser().sub === formatPhoneNumberToId(user.username)
    },
    async switchUser(username, password) {
      this.helpers.credentialsInvalid = false
      this.helpers.rqLoading = true

      const response = await loginCall(this, username, password)
      this.helpers.rqLoading = false

      if (response && response.status === 200) {
        clearPermanentLoggedUser()
        const userToken = getAuthToken()
        const tokenParsed = userToken ? this.parseJwt(userToken) : false
        if (tokenParsed) {
          setUser(tokenParsed)

          addIncentiveForRating(this)

          // nastavime aktivni jako prvni okno
          window.location.href = this.$sysPage('overview')
        } else {
          console.log('token Error')
        }
      } else {
        this.helpers.credentialsInvalid = true
      }
    },
  },
}
</script>
<style lang="scss">
.switchUser {
  &__list {
    padding: 0;
    margin-top: 16px;
    list-style: none;
  }

  &__listItem {
    margin-top: 15px;
    font-size: 1.2rem;
    color: $blue;

    &.active {
      font-weight: $font-weight-bolder;
    }

    > a &:hover {
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 768px) and (prefers-color-scheme: dark) {
  .switchUser__listItem > a {
    color: $blue;
  }
  .editUser__name {
    color: $blue;
  }
}
</style>
