<template>
  <div>
    <Nuxt />
    <transition name="fade">
      <splashScreen v-if="!splashScreenIsHidden"></splashScreen>
    </transition>
    <!-- <cookie-bar></cookie-bar> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getAuthToken } from '../config/common'
import splashScreen from '~/components/splashScreen'

export default {
  name: 'NuxtLayout',
  components: {
    splashScreen,
  },
  computed: mapState(['splashScreenIsHidden']),
  mounted() {
    const callSetEnv = async () => {
      const isLogged = getAuthToken()
      if (!isLogged) return

      const isMobile =
        this.$store.state?.webOrApp === 'app' ||
        this.$store.state?.appPlatform === 'ios'

      await this.$apiCall('/wsc/login/setEnv', {
        method: 'post',
        sendAuth: true,
        data: {
          isMobile,
        },
      })
    }

    callSetEnv()

    window.addEventListener('focus', callSetEnv)
  },
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Sazka Sans';
  src: url('/fonts/SazkaSans-HeavyItalic.woff2') format('woff2'),
    url('/fonts/SazkaSans-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sazka Sans';
  src: url('/fonts/SazkaSans-Medium.woff2') format('woff2'),
    url('/fonts/SazkaSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sazka Sans';
  src: url('/fonts/SazkaSans-Bold.woff2') format('woff2'),
    url('/fonts/SazkaSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sazka Sans';
  src: url('/fonts/SazkaSans-LightItalic.woff2') format('woff2'),
    url('/fonts/SazkaSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sazka Sans';
  src: url('/fonts/SazkaSans-Light.woff2') format('woff2'),
    url('/fonts/SazkaSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sazka Sans';
  src: url('/fonts/SazkaSans-MediumItalic.woff2') format('woff2'),
    url('/fonts/SazkaSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sazka Sans';
  src: url('/fonts/SazkaSans-Heavy.woff2') format('woff2'),
    url('/fonts/SazkaSans-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sazka Sans';
  src: url('/fonts/SazkaSans-Regular.woff2') format('woff2'),
    url('/fonts/SazkaSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sazka Sans';
  src: url('/fonts/SazkaSans-RegularItalic.woff2') format('woff2'),
    url('/fonts/SazkaSans-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sazka Sans';
  src: url('/fonts/SazkaSans-BoldItalic.woff2') format('woff2'),
    url('/fonts/SazkaSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sazka Sans';
  src: url('/fonts/SazkaSans-Heavy.woff2') format('woff2'),
    url('/fonts/SazkaSans-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sazka Sans';
  src: url('/fonts/SazkaSans-BoldItalic.woff2') format('woff2'),
    url('/fonts/SazkaSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sazka Sans';
  src: url('/fonts/SazkaSans-HeavyItalic.woff2') format('woff2'),
    url('/fonts/SazkaSans-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sazka Sans';
  src: url('/fonts/SazkaSans-Medium.woff2') format('woff2'),
    url('/fonts/SazkaSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sazka Sans';
  src: url('/fonts/SazkaSans-Bold.woff2') format('woff2'),
    url('/fonts/SazkaSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sazka Sans';
  src: url('/fonts/SazkaSans-Regular.woff2') format('woff2'),
    url('/fonts/SazkaSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sazka Sans';
  src: url('/fonts/SazkaSans-LightItalic.woff2') format('woff2'),
    url('/fonts/SazkaSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sazka Sans';
  src: url('/fonts/SazkaSans-MediumItalic.woff2') format('woff2'),
    url('/fonts/SazkaSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sazka Sans';
  src: url('/fonts/SazkaSans-Light.woff2') format('woff2'),
    url('/fonts/SazkaSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sazka Sans';
  src: url('/fonts/SazkaSans-RegularItalic.woff2') format('woff2'),
    url('/fonts/SazkaSans-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
</style>
